<script>
  export let show_logo
  export let blue_logo
  export let monitor_width;
  export let monitor_height;
  export let is_phone;
  export let night_theme

  function getMonitor() {
    alert('Your monitor ' + monitor_width + 'x' + monitor_height);
    alert('screen:' + window.screen.width);
  }

  const openModal = () => {
    document.querySelector('.modal_parent').classList.remove('disable_modal')
  }

  function closePopUp() {
    document.getElementById('check').click()
  }
</script>
<style>
  .navbar {
    /*min-height: 63px;*/
  }

  .btn {
    margin-left: auto;
    /*padding-top: 3px;*/
    padding: 0 18px;
    border: 1px solid #111A38;
    box-sizing: border-box;
    border-radius: 4px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact:hover {
    background: #213565;
    color: white;
    /*box-shadow: inset 0 0 0 3px #3a7999;*/
  }

  /*
* If you are thinking of using this in
* production code, beware of the browser
* prefixes.
*/
  body {
    margin: 0;
    padding: 0;
    /* make it look decent enough */
    background: #232323;
    color: #cdcdcd;
    font-family: "Avenir Next", "Avenir", sans-serif;
  }

  #menuToggle {
    display: block;
    /*position: relative;*/
    top: 50px;
    left: 50px;
    margin: 5px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle a {
    text-decoration: none;
    color: #232323;
    transition: color 0.3s ease;
  }

  #menuToggle a:hover {
    /*color: tomato;*/
  }

  #menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: fixed;
    top: -7px;
    /*left: 85%;*/
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }

  /*
   * Just a quick hamburger
   */
  #menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #213565;
    border-radius: 0px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /*
   * Transform all the slices of hamburger
   * into a crossmark.
   */
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #213565;
  }

  /*
   * But let's hide the middle one.
   */
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);

  }

  /*
   * Ohyeah and the last one should go the other direction
   */
  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  /*
   * Make this absolute positioned
   * at the top left of the screen
   */
  #menu {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
    width: 100vw;
    height: 100vh;
    justify-content: flex-start;
    padding-top: 151px;
    /*margin: -100px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;*/
    background: #E9E9EB;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */
    transform-origin: 0% 100%;
    transform: translate(120%, 0%);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
  }

  #menu li {
    padding: 10px 0;
    /*font-size: 22px;*/
  }

  /*
   * And let's slide it in from the left
   */
  #menuToggle input:checked ~ ul {
    transform: none;
  }

  .burger {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
  }

  .blur {
    height: 30px;
    filter: blur(12px);
    width: 100%;
    opacity: 0.2;
    background: #E9E9EB;

  }

  @media screen and (max-width: 568px) and (max-height: 670px) {
    .blur {
      /* display: none; */
    }
  }

  @media (min-height: 1024px) {
    .blur {
      display: none;
    }
  }

  .rout_list {
    margin-right: auto;
    padding: 0 35px 0 35px;
    margin-left: 5%;
  }

  .rout {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #213565;
  }
  .rout.white {
    color: white
  }

  .rout:hover {
    color: #39A0C8;
  }

  .row_direction {
    display: flex;
    flex-direction: row;
    margin-top: 25%;
    width: 100px;


  }

  @media screen and (max-width: 768px) {
    .burger {
      display: flex;
      align-items: center;
    }
  }
</style>
<nav class="navbar navbar-expand-md navbar-dark bg-primary fixed-top"
     style="padding-left: 2.5%; {is_phone ? 'background-image: linear-gradient(to top, rgba(233,233,235, 0), rgba(233,233,235, 1) 30%); padding-bottom: 26px; background-color: transparent !important; min-height: 63px;' : 'background: none !important;'};
        padding-top: 1.5%;
        padding-right: 2.5%;">
    {#if show_logo}
      <a class="navbar-brand" href="#firstPage">
        <img src={night_theme?'imgs/night/white_swarm.svg':'imgs/logo/nav_blue_logo.svg'} alt="" class="">
      </a>
    {/if}
    {#if !is_phone}

      <button class="btn contact" on:click={()=>{openModal()}} style="width: auto;">Contact Us</button>
<!--      <img src={night_theme ? 'imgs/night/world_night.svg' : 'imgs/earth.svg'} alt="" style="padding-left: 59px">-->
<!--      <img src={night_theme ? 'imgs/night/language_night.svg' : 'imgs/language.svg'} alt="" style="padding-left: 21px">-->
    {:else}
      <div class="burger">
        {#if night_theme}
          <img onclick="document.location.href = 'contact-us_night.html'" style="width: 32px; margin-right: 24px"
               src='./imgs/chatNight.svg' alt="">
        {:else}
          <img onclick="document.location.href = 'contact-us_day.html'" style="width: 32px; margin-right: 24px"
               src='./imgs/chatDay.svg' alt="">
        {/if}

        <div id="menuToggle">
          <!--
          A fake / hidden checkbox is used as click reciever,
          so you can use the :checked selector on it.
          -->
          <input type="checkbox" id="check"/>
          <!--
          Some spans to act as a hamburger.
          They are acting like a real hamburger,
          not that McDonalds stuff.
          -->
          <span></span>
          <span></span>
          <span></span>
          <!--
          Too bad the menu has to be inside of the button
          but hey, it's pure CSS magic.
          -->

          <ul id="menu">
            <div class="rout_list">
              <a href="#home" on:click={()=>closePopUp()}>
                <li class="rout {night_theme ? 'white': ''}">HOME</li>
              </a>
              <a href="#about" on:click={()=>closePopUp()}>
                <li class="rout {night_theme ? 'white': ''}" >ABOUT</li>
              </a>
              <a href="#our-portfolio" on:click={()=>closePopUp()}>
                <li class="rout {night_theme ? 'white': ''}">OUR PORTFOLIO</li>
              </a>
              <a href="#values" on:click={()=>closePopUp()}>
                <li class="rout {night_theme ? 'white': ''}">VALUES</li>
              </a>
              <a href="#customers" on:click={()=>closePopUp()}>
                <li class="rout {night_theme ? 'white': ''}">CUSTOMERS</li>
              </a>
              <a href="#contact-us">
                <li class="rout {night_theme ? 'white': ''}" on:click={()=>closePopUp()}>CONTACT US</li>
              </a>
              <!--              <a href="contact-us.html">-->
              <!--                <li class="rout" on:click={()=>closePopUp()}>GET IN TOUCH-->
              <!--                  <img style="width: 32px; margin-left: 4px"-->
              <!--                       src={night_theme ? './imgs/chatNight.svg' : './imgs/chatDay.svg'}-->
              <!--                       alt="">-->
              <!--                </li>-->
              <!--              </a>-->
              <!--<div class="row_direction">
                  <a href="#"><p class="rout">EN</p></a>
                  <a href="#" style="padding-left: 30px;"><p class="rout">EN</p></a>
              </div>-->
            </div>
          </ul>
        </div>
      </div>
    {/if}
</nav>
{#if is_phone}
  <div class="blur"></div>
{/if}
