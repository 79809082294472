<script>
  import { fade } from "svelte/transition";
  import { DrawLines } from "../Helpers/DrawLine";
  import { DrawWhiteLines } from "../Helpers/DrawWhiteLine";
  import { DrawZipLine } from "../Helpers/DrowZipLine";

  import {onMount} from 'svelte'

  // export let is_phone;
  export let night_theme;
  export let c_timer;
  export let first_bird_active;
  export let second_bird_active;
  export let third_bird_active;
  export let fourth_bird_active;
  export let fifth_bird_active;
  export let first_customer_section;
  export let second_customer_section;
  export let third_customer_section;
  export let fourth_customer_section;
  export let first_customer_section_mobile;
  export let second_customer_section_mobile;
  export let third_customer_section_mobile;
  export let fourth_customer_section_mobile;
  export let between_first_bird;
  export let between_second_bird;
  export let between_third_bird;
  export let between_forth_bird;
  export let between_five_bird;
  export let between_six_bird;
  export let between_seven_bird;
  export let between_eight_bird;
  export let between_nine_bird;
  export let between_ten_bird;
  export let between_eleven_bird;
  export let between_twelve_bird;
  export let show_description;
  export let show_all;

  const is_phone = window.matchMedia("(max-width: 768px)").matches;

  function viewAll() {
    jq(".customer_line").remove();
    show_all = true;
    setTimeout(() => {
      show_description = true;
    }, 2000);
    jq(".customer_line").remove();
    setTimeout(() => {
      DrawZipLine(0);
      DrawZipLine(1);
      DrawZipLine(2);
    }, 3500);
    if (!night_theme) {
      setTimeout(() => DrawLines(0), 3200);
      setTimeout(() => DrawLines(1), 3200);
      setTimeout(() => DrawLines(2), 3200);
      setTimeout(() => DrawLines(3), 3200);
    } else {
      setTimeout(() => DrawWhiteLines(0), 3200);
      setTimeout(() => DrawWhiteLines(1), 3200);
      setTimeout(() => DrawWhiteLines(2), 3200);
      setTimeout(() => DrawWhiteLines(3), 3200);
    }
  }
</script>

{#if !is_phone}
  <div class="container" style="min-height: 450px">
    {#if !show_all}
      <div transition:fade class="row" style="min-height: 450px">
        {#if c_timer}
          <div class="col-2" />
          <div class="col-1">
            <img
              src={night_theme ? "imgs/night/c.svg" : "imgs/c.svg"}
              alt=""
              class="c"
            />
          </div>
          <div class="col-7">
            <div style="position: absolute; top: 30%">
              <p
                class="font_22_12 "
                style="color: #213565; font-size: 26px; font-weight: 300;"
              >
                CUSTOMERS
              </p>
              <p
                class="font_44_bold_34"
                style="font-size: 40px; color: #111A38; line-height: 53px;"
              >
                {window.innerWidth <= 1280 && window.innerHeight <= 800
                        ? 'A platform serving and connecting stakeholders'
                        : 'A platform serving and connecting stakeholders, forming a strong\n' +
                        '                ecosystem that empowers people and strengthens the collective\n' +
                        '                intelligence.'}
              </p>
            </div>
          </div>
          <div class="col-2" />
        {:else}
          {#if first_customer_section}
            <div class="col-1" />
            <div class="col-4">
              <div class="first_text" id="block_1">
                <p
                  class="font_22_12 fix_width bold text_block"
                  transition:fade={{ duration: 1300 }}
                  style="font-weight: bold; font-size: 36px; line-height: 32px;"
                >
                  PENSION FUNDS & BENEFICIARIES
                </p>
                <p
                  class="font_22_12 fix_width"
                  transition:fade={{ duration: 1300 }}
                  style="font-size: 22px; line-height: 36px; color: #111A38;"
                >
                  Facilitation and automation of workflows of pension funds.
                </p>
              </div>
            </div>
            <div class="col-7" />
          {/if}

          {#if second_customer_section}
            <div class="col-3" />
            <div class="col-4">
              <div class="second_text text_block" id="block_2">
                <p
                  class="font_22_12 fix_width bold text_block"
                  transition:fade={{ duration: 1300 }}
                  style="font-weight: bold; font-size: 36px; line-height: 32px;"
                >
                  SCHOOLS & STUDENTS
                </p>
                <p
                  class="font_22_12 fix_width"
                  transition:fade={{ duration: 1300 }}
                  style="font-size: 22px; line-height: 36px; color: #111A38;"
                >
                  Supporting school ecosystems in organizing new ways of
                  learning.
                </p>
              </div>
            </div>
            <div class="col-5" />
          {/if}

          {#if third_customer_section}
            <div class="col-6" />
            <div class="col-4">
              <div class="third_text text_block" id="block_3">
                <p
                  class="font_22_12 fix_width bold text_block"
                  transition:fade={{ duration: 1300 }}
                  style="font-weight: bold; font-size: 36px; line-height: 32px;"
                >
                  HOSPITALS & PATIENTS
                </p>
                <p
                  class="font_22_12 fix_width"
                  transition:fade={{ duration: 1300 }}
                  style="font-size: 22px; line-height: 36px; color: #111A38;"
                >
                  Helping hospitals to digitalize their infrastructure and
                  organization.
                </p>
              </div>
            </div>
            <div class="col-2" />
          {/if}

          {#if fourth_customer_section}
            <div class="col-7" />
            <div class="col-4">
              <div class="fourth_text" id="block_4">
                <p
                  class="font_22_12 fix_width bold text_block"
                  transition:fade={{ duration: 1300 }}
                  style="font-weight: bold; font-size: 36px; line-height: 32px;"
                >
                  LANDLORDS & TENANTS
                </p>
                <p
                  class="font_22_12 fix_width"
                  transition:fade={{ duration: 1300 }}
                  style="font-size: 22px; line-height: 36px; color: #111A38;"
                >
                  Simplification and promotion of exchange of information and
                  data between all stakeholders of a property.
                </p>
              </div>
            </div>
            <div class="col-1" />
          {/if}
        {/if}
      </div>
      <div
        class="row"
        style="position: fixed; width: 100%; bottom: 20%; max-width: 1280px;"
        transition:fade={{ duration: 2000 }}
      >
        <div class="col-1" />
        <div class="col-3">
          {#if !night_theme}
            <img
              src={first_bird_active
                ? "imgs/birds/bird_1_blue.svg"
                : "imgs/birds/bird_1_white.svg"}
              alt=""
              class="first_bird bird_c size_bird"
            />
            <img
              src={second_bird_active
                ? "imgs/birds/bird_2_blue.svg"
                : "imgs/birds/bird_2_white.svg"}
              alt=""
              class="second_bird bird_c size_bird"
            />
            <img
              src={third_bird_active
                ? "imgs/birds/bird_3_blue.svg"
                : "imgs/birds/bird_3_white.svg"}
              alt=""
              class="third_bird bird_c size_bird"
            />
            <img
              src={fourth_bird_active
                ? "imgs/birds/bird_4_blue.svg"
                : "imgs/birds/bird_4_white.svg"}
              alt=""
              class="fourth_bird bird_c size_bird"
            />
            <img
              src={fifth_bird_active
                ? "imgs/birds/bird_5_blue.svg"
                : "imgs/birds/bird_5_white.svg"}
              alt=""
              class="fifth_bird bird_c size_bird"
            />
          {:else}
            <img
              src={first_bird_active
                ? "imgs/birds/bird_1_blue.svg"
                : "imgs/birds/bird_1_blue_blur.svg"}
              alt=""
              class="first_bird bird_c size_bird"
            />
            <img
              src={second_bird_active
                ? "imgs/birds/bird_2_blue.svg"
                : "imgs/birds/bird_2_blue_blur.svg"}
              alt=""
              class="second_bird bird_c size_bird"
            />
            <img
              src={third_bird_active
                ? "imgs/birds/bird_3_blue.svg"
                : "imgs/birds/bird_3_blue_blur.svg"}
              alt=""
              class="third_bird bird_c size_bird"
            />
            <img
              src={fourth_bird_active
                ? "imgs/birds/bird_4_blue.svg"
                : "imgs/birds/bird_4_blue_blur.svg"}
              alt=""
              class="fourth_bird bird_c size_bird"
            />
            <img
              src={fifth_bird_active
                ? "imgs/birds/bird_5_blue.svg"
                : "imgs/birds/bird_5_blue_blur.svg"}
              alt=""
              class="fifth_bird bird_c size_bird"
            />
          {/if}
        </div>
        <div class="col-8">
          <div class="row" style="padding-top: 40px">
            <div class="col-1">
              {#if night_theme}
                <img
                  src={first_customer_section
                    ? "imgs/bird_set_2/bird_1_gray.svg"
                    : "imgs/bird_set_2/bird_1_blue.svg"}
                  alt=""
                  class="six_bird bird_55 "
                  id="bird_1"
                />
              {:else}
                <img
                  src={first_customer_section
                    ? "imgs/bird_set_2/bird_1_blue.svg"
                    : "imgs/bird_set_2/bird_1_gray.svg"}
                  alt=""
                  class="six_bird bird_55 "
                  id="bird_1"
                />
              {/if}
            </div>
            <div class="col-2" style="padding: 0px">
              <img
                src={between_first_bird
                  ? "imgs/birds/bird_1_blue.svg"
                  : "imgs/birds/bird_hiden.svg"}
                alt=""
                class="between_bird bird_c size_bird"
              />
              <img
                src={between_second_bird
                  ? "imgs/birds/bird_2_blue.svg"
                  : "imgs/birds/bird_hiden.svg"}
                alt=""
                class="between_bird bird_c size_bird"
              />
              <img
                src={between_third_bird
                  ? "imgs/birds/bird_3_blue.svg"
                  : "imgs/birds/bird_hiden.svg"}
                alt=""
                class="between_bird bird_c size_bird"
              />
              <img
                src={between_forth_bird
                  ? "imgs/birds/bird_4_blue.svg"
                  : "imgs/birds/bird_hiden.svg"}
                alt=""
                class="between_bird bird_c size_bird"
              />
            </div>
            <div class="col-1">
              {#if night_theme}
                <img
                  src={second_customer_section
                    ? "imgs/bird_set_2/bird_2_gray.svg"
                    : "imgs/bird_set_2/bird_2_blue.svg"}
                  alt=""
                  class="seven_bird bird_55"
                  id="bird_2"
                />
              {:else}
                <img
                  src={second_customer_section
                    ? "imgs/bird_set_2/bird_2_blue.svg"
                    : "imgs/bird_set_2/bird_2_gray.svg"}
                  alt=""
                  class="seven_bird bird_55"
                  id="bird_2"
                />
              {/if}
            </div>
            <div class="col-2" style="padding: 0px">
              <img
                src={between_five_bird
                  ? "imgs/birds/bird_1_blue.svg"
                  : "imgs/birds/bird_hiden.svg"}
                alt=""
                class="between_bird bird_c size_bird"
              />
              <img
                src={between_six_bird
                  ? "imgs/birds/bird_2_blue.svg"
                  : "imgs/birds/bird_hiden.svg"}
                alt=""
                class="between_bird bird_c size_bird"
              />
              <img
                src={between_seven_bird
                  ? "imgs/birds/bird_3_blue.svg"
                  : "imgs/birds/bird_hiden.svg"}
                alt=""
                class="between_bird bird_c size_bird"
              />
              <img
                src={between_eight_bird
                  ? "imgs/birds/bird_4_blue.svg"
                  : "imgs/birds/bird_hiden.svg"}
                alt=""
                class="between_bird bird_c size_bird"
              />
            </div>
            <div class="col-1">
              {#if night_theme}
                <img
                  src={third_customer_section
                    ? "imgs/bird_set_2/bird_3_gray.svg"
                    : "imgs/bird_set_2/bird_3_blue.svg"}
                  alt=""
                  class="eight_bird bird_55"
                  id="bird_3"
                />
              {:else}
                <img
                  src={third_customer_section
                    ? "imgs/bird_set_2/bird_3_blue.svg"
                    : "imgs/bird_set_2/bird_3_gray.svg"}
                  alt=""
                  class="eight_bird bird_55"
                  id="bird_3"
                />
              {/if}
            </div>
            <div class="col-2" style="padding: 0px">
              <img
                src={between_nine_bird
                  ? "imgs/birds/bird_1_blue.svg"
                  : "imgs/birds/bird_hiden.svg"}
                alt=""
                class="between_bird bird_c size_bird"
              />
              <img
                src={between_ten_bird
                  ? "imgs/birds/bird_2_blue.svg"
                  : "imgs/birds/bird_hiden.svg"}
                alt=""
                class="between_bird bird_c size_bird"
              />
              <img
                src={between_eleven_bird
                  ? "imgs/birds/bird_3_blue.svg"
                  : "imgs/birds/bird_hiden.svg"}
                alt=""
                class="between_bird bird_c size_bird"
              />
              <img
                src={between_twelve_bird
                  ? "imgs/birds/bird_4_blue.svg"
                  : "imgs/birds/bird_hiden.svg"}
                alt=""
                class="between_bird bird_c size_bird"
              />
            </div>
            <div class="col-1">
              {#if night_theme}
                <img
                  src={fourth_customer_section
                    ? "imgs/bird_set_2/bird_4_gray.svg"
                    : "imgs/bird_set_2/bird_4_blue.svg"}
                  alt=""
                  class="nine_bird bird_55"
                  id="bird_4"
                />
              {:else}
                <img
                  src={fourth_customer_section
                    ? "imgs/bird_set_2/bird_4_blue.svg"
                    : "imgs/bird_set_2/bird_4_gray.svg"}
                  alt=""
                  class="nine_bird bird_55"
                  id="bird_4"
                />
              {/if}
            </div>
            <div class="col-2" />
            <button class="view_all_button" on:click={() => viewAll()}>
              VIEW ALL <img
                src={night_theme
                  ? "imgs/two_arrows_yellow.svg"
                  : "imgs/arrows.svg"}
                alt=""
                style="padding-bottom: 4px"
              />
            </button>
          </div>
        </div>
      </div>
    {:else if show_description}
      <div class="row" style="height: 53%; min-height: 471px;">
        <div class="col-lg-1 col-md-1" />
        <div class="col-2" style="min-height: 471px;">
          <div class="first_text" id="block_1" style="margin-top: 30%">
            <p
              class="font_22_12 fix_width bold"
              transition:fade={{ duration: 1300 }}
              style="font-weight: bold; font-size: 22px; line-height: 29px;"
            >
              PENSION FUNDS & BENEFICIARIES
            </p>
            <p
              class="font_22_12 fix_width"
              transition:fade={{ duration: 1300 }}
              style="font-size: 22px; line-height: 29px;"
            >
              Facilitation and automation of workflows of pension funds.
            </p>
          </div>
        </div>
        <div class="col-1" />
        <div class="col-2">
          <div class="second_text " id="block_2" style="margin-top:5%">
            <p
              class="font_22_12 fix_width bold"
              transition:fade={{ duration: 1300 }}
              style="font-weight: bold; font-size: 22px; line-height: 29px;"
            >
              SCHOOLS & STUDENTS
            </p>
            <p
              class="font_22_12 fix_width"
              transition:fade={{ duration: 1300 }}
              style="font-size: 22px; line-height: 29px;"
            >
              Supporting school ecosystems in organizing new ways of learning.
            </p>
          </div>
        </div>
        <div class="col-1" />
        <div class="col-2">
          <div class="third_text " id="block_3" style="margin-top:15%">
            <p
              class="font_22_12 fix_width bold"
              transition:fade={{ duration: 1300 }}
              style="font-weight: bold; font-size: 22px; line-height: 29px;"
            >
              HOSPITALS & PATIENTS
            </p>
            <p
              class="font_22_12 fix_width"
              transition:fade={{ duration: 1300 }}
              style="font-size: 22px; line-height: 29px;"
            >
              Helping hospitals to digitalize their infrastructure and
              organization.
            </p>
          </div>
        </div>
        <div class="col-1" />
        <div class="col-2">
          <div class="fourth_text" id="block_4">
            <p
              class="font_22_12 fix_width bold"
              transition:fade={{ duration: 1300 }}
              style="font-weight: bold; font-size: 22px; line-height: 29px;"
            >
              LANDLORDS & TENANTS
            </p>
            <p
              class="font_22_12 fix_width"
              transition:fade={{ duration: 1300 }}
              style="font-size: 22px; line-height: 29px;"
            >
              Simplification and promotion of exchange of information and data
              between all stakeholders of a property.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-1" />
        <div class="col-1">
          {#if night_theme}
            <img
              src="imgs/bird_set_2/bird_2_gray.svg"
              alt=""
              class="six_bird bird_55 left_bird"
              id="bird_1"
            />
          {:else}
            <img
              src="imgs/bird_set_2/bird_1_blue.svg"
              alt=""
              class="six_bird bird_55 left_bird"
              id="bird_1"
            />
          {/if}
        </div>
        <div class="col-2" />
        <div class="col-1">
          {#if night_theme}
            <img
              src="imgs/bird_set_2/bird_2_gray.svg"
              alt=""
              class="seven_bird bird_55 left_bird"
              id="bird_2"
            />
          {:else}
            <img
              src="imgs/bird_set_2/bird_2_blue.svg"
              alt=""
              class="seven_bird bird_55 left_bird"
              id="bird_2"
            />
          {/if}
        </div>
        <div class="col-2" />
        <div class="col-1">
          {#if night_theme}
            <img
              src="imgs/bird_set_2/bird_3_gray.svg"
              alt=""
              class="eight_bird bird_55 left_bird"
              id="bird_3"
            />
          {:else}
            <img
              src="imgs/bird_set_2/bird_3_blue.svg"
              alt=""
              class="eight_bird bird_55 left_bird"
              id="bird_3"
            />
          {/if}
        </div>
        <div class="col-2" />
        <div class="col-1">
          {#if night_theme}
            <img
              src="imgs/bird_set_2/bird_4_gray.svg"
              alt=""
              class="nine_bird bird_55 left_bird"
              id="bird_4"
            />
          {:else}
            <img
              src="imgs/bird_set_2/bird_4_blue.svg"
              alt=""
              class="nine_bird bird_55 left_bird"
              id="bird_4"
            />
          {/if}
        </div>
        <div class="col-1" />
      </div>
    {/if}
  </div>

{:else}
  <div class="container" style="height: 100%;{is_phone ? 'padding: 0 0 100px 0;' : ''}">
    <div
      class="row"
      id="section-five-mob"
      style={is_phone ? "padding-top: 12%;" : "paddig-top: 0"}
    >
      <div class="col-1" />
      <div class="col-9" style="height: 540px; max-height: 540px">
        <div class="content_mobile">
          <div class="first_text" id="block_1">
            {#if first_customer_section_mobile}
              <div class="title_bird">
                <p
                  class="title fix_width bold"
                  transition:fade
                  style="font-weight: bold; font-size: 26px; line-height: 30px;"
                >
                  PENSION FUNDS & BENEFICIARIES
                </p>
                <img
                  src="imgs/bird_set_2/bird_1_blue.svg"
                  alt=""
                  class="six_bird bird_55 mobile_bird"
                  id="bird_1"
                />
              </div>

              <p class="font_22_12 fix_width" transition:fade>
                Facilitation and automation of workflows of pension funds.
              </p>
            {/if}
          </div>
          <div class="second_text " id="block_2">
            {#if second_customer_section_mobile}
              <div class="title_bird">
                <p
                  class="title fix_width bold"
                  transition:fade
                  style="font-weight: bold; font-size: 26px; line-height: 30px;margin-top: 40px;"
                >
                  SCHOOLS & STUDENTS
                </p>
                <img
                  src="imgs/bird_set_2/bird_2_blue.svg"
                  alt=""
                  class="seven_bird bird_55 mobile_bird"
                  id="bird_2"
                />
              </div>
              <p class="font_22_12 fix_width" transition:fade>
                Supporting school ecosystems in organizing new ways of learning.
              </p>
            {/if}
          </div>
          <div class="third_text " id="block_3">
            {#if third_customer_section_mobile}
              <div class="title_bird">
                <p
                  class="title fix_width bold"
                  transition:fade
                  style="font-weight: bold; font-size: 26px; line-height: 30px; margin-top: 40px;"
                >
                  HOSPITALS & PATIENTS
                </p>
                <img
                  src="imgs/bird_set_2/bird_3_blue.svg"
                  alt=""
                  class="eight_bird bird_55 mobile_bird"
                  id="bird_3"
                />
              </div>
              <p class="font_22_12 fix_width" transition:fade>
                Helping hospitals to digitalize their infrastructure and
                organization.
              </p>
            {/if}
          </div>
          <div class="fourth_text" id="block_4">
            {#if fourth_customer_section_mobile}
              <div class="title_bird">
                <p
                  class="title fix_width bold"
                  transition:fade
                  style="font-weight: bold; font-size: 26px; line-height: 30px; margin-top: 40px;"
                >
                  LANDLORDS & TENANTS
                </p>
                <img
                  src="imgs/bird_set_2/bird_4_blue.svg"
                  alt=""
                  class="nine_bird bird_55 mobile_bird"
                  id="bird_4"
                />
              </div>
              <p class="font_22_12 fix_width" transition:fade>
                Simplification and promotion of exchange of information and data
                between all stakeholders of a property.
              </p>
            {/if}
          </div>
        </div>
      </div>
      <div class="col-3">
        <!-- <div class="bird_menu_mobile">
                 </div>-->
      </div>
    </div>
  </div>
{/if}


<style>
  .c {
    /*position: absolute;
        left: 1%;
        top: 10%;*/
    position: absolute;
    height: 500px;
    padding-right: 110px;
    margin: auto 0;
    z-index: 0;
  }

  .content_svg {
    height: 10%;
  }

  .content_section {
    /*background-color: #E9E9EB;*/
  }

  .customer_message {
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 57px;
    font-feature-settings: "liga" off;
    color: #111a38;
    margin-bottom: 110px;
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    text-transform: uppercase;
    color: #111a38;
  }

  .back {
    /*background-color: #E9E9EB !important;*/
  }

  .text {
    width: 708px;
    height: 171px;
    max-width: 708px;
    max-height: 171px;
  }

  .first_bird {
    padding-top: 30px;
    padding-left: 22px;
  }

  .second_bird {
    padding-top: 60px;
    padding-left: 19px;
  }

  .third_bird {
    padding-top: 70px;
    padding-left: 24px;
  }

  .fourth_bird {
    padding-top: 30px;
    padding-left: 21px;
  }

  .fifth_bird {
    padding-top: 30px;
    padding-left: 26px;
  }

  .six_bird {
    /*padding-left: 20%;*/
    padding-top: 2%;
  }

  /*.seven_bird {
        padding-left: 12%;
    }

    .eight_bird {
        padding-left: 12%;
    }

    .nine_bird {
        padding-left: 12%;
    }*/

  .bird_menu {
    position: absolute;
    left: 90px;
    top: 75%;
  }

  .font_44_bold_34 {
    /*line-height: 37px;*/
  }

  @media (max-width: 1440px) {
    .c {
      /*height: 11%;
            padding-left: 200px;;*/
    }
  }

  @media (min-width: 1400px) {
    .bird_menu {
      padding-left: 6%;
    }
  }

  @media (max-width: 1024px) {
    .bird_menu {
      position: absolute;
      left: 70%;
      top: 10%;
    }
  }

  @media (min-width: 768px) {
    @media (max-width: 1024px) {
      .bird_menu {
        position: absolute;
        top: 70%;
        left: 5%;
      }

      .c_mobile {
        height: 60% !important;
      }
    }
  }

  .c_mobile {
    height: 50%;
    position: absolute;
  }

  @media (width: 1024px) {
    .c {
      /*width: 60%;*/
    }

    .font_44_bold_34 {
      font-size: 32px;
      line-height: 45px;
    }
  }

  @media (min-width: 1440px) {
    .font_44_bold_34 {
      font-size: 56px;
      line-height: 75px;
    }
  }

  .container {
    max-width: 1440px;
  }

  .svelte_font {
    font-weight: 300 !important;
  }

  .fix_height {
    height: 100%;
  }

  .between_bird {
    z-index: 1;
  }

  .view_all_button {
    position: absolute;
    bottom: -100%;
    left: 80%;
    /*left: 85%;*/
    color: #39a0c8;
    outline: none; /* Для синий ободки */
    border: 0;
    background: transparent;
  }

  .content_mobile {
    height: 110vh;
    flex-direction: column;
    align-items: center;
    vertical-align: center;
  }

  .mobile_bird {
    position: absolute;
    right: -20%;
  }

  .text_block {
    margin-top: 15%;
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (hover: none) {
    .size_bird {
      width: 18%;
    }
  }

  @media screen and (max-width: 768px) {
    .mobile_bird {
      position: absolute;
      right: -13%;
    }

    .content_mobile {
      margin-left: 8%;
      height: 100%;
      /*height: 545px;*/
      /*max-height: 545px;*/
    }

    .hide_mob {
      display: none;
    }

    .title {
      font-size: 24px !important;
      margin-bottom: 8px;
    }
  }

  .left_bird {
    margin-left: 50px;
  }

  @media screen and (max-width: 1024px) and (orientation: portrait) {
      .font_22_12 {
          font-size: 20px !important;
          line-height: 1 !important;
      }
  }

  @media (max-width: 400px) {
    .title {
      font-size: 20 !important;
      line-height: 1 !important;
    }
    .font_22_12 {
      font-size: 18px !important;
    }
    /* .section-five-mob {
      height: calc(100vh - 70px) !important;
    } */
  }
</style>
