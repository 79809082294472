<script>
    import {fade, slide} from 'svelte/transition';
    import {beforeUpdate, afterUpdate} from 'svelte';
    import {onMount} from 'svelte';

    export let show_first_section_mission
    export let animated
    export let is_phone
    export let animate
    export let stop_animation
    let animation_over = true
    let can_close = false
    let visible = false
    export let show_p_missions
    let show_third_page_descriptions = {
        show_first_description: false,
        show_second_description: false,
        show_third_description: false
    }
    if (is_phone) {
      show_third_page_descriptions = {
        show_first_description: true,
        show_second_description: true,
        show_third_description: true
    }
    }

    export let start_third_animation
    export let night_theme

    function mouselog(selector, text, img, show) {
            if (animation_over) {
                animation_over = false;
                if (!night_theme) {
                    jq("." + text).css("color", "#39A0C8");
                    jq("." + img).attr("src", "imgs/plus_blue.svg").css("transform", "rotate(0deg)");
                } else {
                    jq("." + text).attr("style", "color: #C5A01E !important")
                    jq("." + img).attr("src", "imgs/night/show_mission/close.svg").css("transform", "rotate(0deg)");
                }
                switch (selector) {
                    case 'first_description':
                        show_third_page_descriptions.show_first_description = true
                        break
                    case 'second_description':
                        show_third_page_descriptions.show_second_description = true
                        break
                    case 'third_description':
                        show_third_page_descriptions.show_third_description = true
                        break
                }
                can_close = true
                setTimeout(() => {
                    animation_over = true
                }, 100)
            }
    }

    function mouseover(selector, text, img, show) {
        if (!night_theme) {
            jq("." + text).css("color", "#111A38")
            jq("." + img).css("transform", "rotate(45deg)").attr("src", "./imgs/plus.svg");
        } else {
            jq("." + text).attr("style", "color: white !important")
            jq("." + img).css("transform", "rotate(45deg)").attr("src", "./imgs/night/show_mission/open.svg");
        }
        switch (selector) {
            case 'first_description':
                show_third_page_descriptions.show_first_description = false
                break
            case 'second_description':
                show_third_page_descriptions.show_second_description = false
                break
            case 'third_description':
                show_third_page_descriptions.show_third_description = false
                break
        }
    }

    beforeUpdate(() => {
        if(show_first_section_mission && !stop_animation) {
            if  (!animated && !is_phone) {
                mouselog('first_description', 'first_text', 'first_img')
                setTimeout(() => {
                  if (!is_phone) {
                    mouseover('first_description', 'first_text', 'first_img')
                  }
                    mouselog('second_description', 'second_text', 'second_img')
                }, 4000)
                setTimeout(() => {
                  if (!is_phone) {
                    mouseover('second_description', 'second_text', 'second_img')
                  }
                    mouselog('third_description', 'third_section', 'third_img')
                }, 8000)
                setTimeout(() => {
                  if (!is_phone) {
                    mouseover('third_description', 'third_section', 'third_img')
                  }
                }, 12000)
                animated = true                
            } else if(is_phone) {          
                show_third_page_descriptions.show_first_description = true
                show_third_page_descriptions.show_second_description = true
                show_third_page_descriptions.show_third_description = true
        }

        }
    })
</script>
<style>


    .pointer {
        cursor: pointer;
    }

    .our_mission {
        padding-bottom: 3%;
    }

    .font_44_bold_34 {
        /*color: #39A0C8;*/
        color: #111A38;
    }

    .flexed_box {
        padding-top: 18px
    }

    @media (min-width: 1300px) {
        .our_mission {
            width: 120%;
        }
    }

    @media (max-width: 1280px) {
        .our_mission {
            width: 70%;
        }

        @media (device-height: 1024px) {
            @media (device-width: 1280px) {
                .our_mission {
                    width: 120%;
                }
            }

        }
    }
    @media screen and (max-width: 768px) {
        .plus_info {
          display: none;
        }
        .yellow {
          color: #C5A01E !important;
        }
        .blue {
          color: #39A0C8 !important;
        }
    }

    @media (max-height: 540px) {
        .our_mission {
            width: 100%;
            padding-left: 2%;
        }

        .flexed_box {
            padding-top: 0px;
        }

        .text_left {
            text-align: left;
        }
    }

    .align_text {
        padding-top: 5px;
        margin-bottom: 0px;
        position: absolute;
        font-weight: 400;
    }

    @media (max-width: 1024px) {
        .no_padding {
            padding-left: 0 !important;
        }

        @media (max-height: 768px) {
            .font_44_bold_34 {
                font-size: 32px;
            }
        }
    }

    @media (width: 768px) {
        .align_text {
            font-size: 18px;
            line-height: 27px;
        }
    }

    .back {
        background-color: #E9EAED;
    }

    .transform {
        transform: rotate(45deg);
    }
  @media screen and (max-width: 568px) and (max-height: 670px) {
      .max-height {
        height: 105vh !important;
      }
  }
  @media only screen and (max-width: 568px) and (min-height: 670px) {
      .max-height {
        height: 100vh !important;
      }
  }
  @media screen and (max-width: 768px) {
    .align_text {
      padding-top: 0 !important;
      padding-bottom: 30px !important;
    }
    .pb0 {
      padding-bottom: 0 !important;
    }
    .mb8 {
      margin-bottom: 8px !important;
    }
  }

  @media screen and (max-width: 1024px) and (orientation: portrait) {
      .content_block {
          margin-left: 120px;
      }
  }


  @media (max-width: 400px) {
        .font_44_bold_34 {
            font-size: 20px;
        }
        .content_block {
          margin-left: 25px;
      }
      .our_mission {
          width: 60% !important;
          padding-left: 15px;
      }
      .mobile_pad {
          margin-top: 0;
      }
      .wow.fadeIn {
          margin-top: -40px !important;
      }
      .text_left.pointer {
          margin-bottom: 0 !important;
      }
      .left_text.align_text, .text-left.align_text {
          padding-bottom: 10px !important;
          font-size: 18px !important;
      }
      .row.max-height {
          height: 115vh !important;
      }
    }



</style>
<div class="row max-height" style="display: flex; {is_phone ? 'height: 110vh;' : ''}">
    {#if animate}
    <div class="col-12 content_section back" transition:fade="{{ duration: is_phone ? 500 : 100 }}">
        <!--style={night_theme ? 'color: #040019 !important;' : 'color: #111A38 !important;'}-->
        <!--OUR MISSIONS-->
        <div class="content_block">
            <div class="row" style="display: inline-block;">
                <div class="col-12" style=" text-align: left;  display: inline-block;">
                    <div class="wow fadeIn" data-wow-duration="1s">
                        <img src={night_theme ? 'imgs/our-portfolio.svg' : './imgs/our-portfolio_day.svg'}
                        class="our_mission" alt="">
                    </div>
                </div>
            </div>
            <br>
            <div class="wow fadeIn" style="{is_phone ? 'margin-top: -30px;' : 'margin-top: 0'}" data-wow-duration="1s">
                <div class="mobile_pad">

                    <div class="row" transition:fade>
                        <div class="col-0 col-md-0 col-lg-3"></div>
                        <div class="col-12 col-md-10 col-lg-9 no_padding">
                            <!--<span class="font_44_bold_34 text_left first_text {night_theme ? 'yellow' : 'blue'}"
                                  style="margin-bottom: 0px !important;">
                                Collective</span>-->
                        </div>
                        <div class="col-0 col-md-2 col-lg-3"></div>
                        <div class="col-8 col-md-4 col-lg-3 col-xl-3 no_padding">
                            <p class="font_44_bold_34 text_left first_text pointer mb8 {night_theme ? 'yellow' : 'blue'}"
                               on:mouseover="{() => !is_phone
                                && !show_third_page_descriptions.show_first_description
                                && !show_third_page_descriptions.show_second_description
                                && !show_third_page_descriptions.show_third_description ? mouselog('first_description', 'first_text', 'first_img'): null}"
                               on:mouseout="{() => !is_phone ? mouseover('first_description', 'first_text', 'first_img') : null}">
                                Pension</p>
                        </div>

                        <div class="col-3 col-md-1 col-lg-1 flexed_box">
                            <button class="plus_info auto_margin margin_top_plus_button x_button pointer"
                                    on:mouseover="{() => !is_phone && !show_third_page_descriptions.show_first_description
                                && !show_third_page_descriptions.show_second_description
                                && !show_third_page_descriptions.show_third_description ? mouselog('first_description', 'first_text', 'first_img') : null}"
                                    on:mouseout="{() => !is_phone ? mouseover('first_description', 'first_text', 'first_img') : null}">
                                <img class="x_button_img first_img transform"  src={night_theme ?
                                'imgs/night/show_mission/open.svg' : 'imgs/plus.svg'} alt="">
                            </button>
                        </div>

                        <div class="col-xs-1 col-md-0"></div>
                        <div class="col-12 col-md-7 col-lg-5 col-xl-5 first_description" style="padding-left: 0px;">
                            {#if show_third_page_descriptions.show_first_description}
                            {#if !is_phone}
                            <p class="font_26_16_v2 left_text text_left align_text" transition:fade
                            >Making the personal
                                retirement business a collective
                                affair again and combine intelligence to secure a safe
                                retirement for everyone.</p>
                            {:else}
                            <p class="font_26_16_v2 left_text text_left align_text" transition:slide
                            >Making the personal
                                retirement business a collective
                                affair again and combine intelligence to secure a safe
                                retirement for everyone.</p>
                            {/if}
                            {/if}
                        </div>
                        <div class="col-0 col-md-0"></div>

                    </div>

                    <!---------------------------------------------->

                    <div class="row" transition:fade>
                        <div class="col-0 col-md-0 col-lg-3"></div>
                        <div class="col-8 col-md-4 col-lg-3 col-xl-3 no_padding">
                            <p class="font_44_bold_34 text_left second_text pointer mb8 {night_theme ? 'yellow' : 'blue'}"
                               on:mouseover="{() => !is_phone && !show_third_page_descriptions.show_first_description
                                && !show_third_page_descriptions.show_second_description
                                && !show_third_page_descriptions.show_third_description ? mouselog('second_description', 'second_text', 'second_img') : null}"
                               on:mouseout="{() => !is_phone ? mouseover('second_description', 'second_text', 'second_img') : null}">

                                Access</p>
                        </div>
                        <div class="col-3 col-md-1 flexed_box">
                            <button class="plus_info auto_margin margin_top_plus_button x_button pointer"
                                    on:mouseover="{() => !is_phone && !show_third_page_descriptions.show_first_description
                                && !show_third_page_descriptions.show_second_description
                                && !show_third_page_descriptions.show_third_description ? mouselog('second_description', 'second_text', 'second_img') : null}"
                                    on:mouseout="{() => !is_phone ? mouseover('second_description', 'second_text', 'second_img') : null}">
                                <img src={night_theme ? 'imgs/night/show_mission/open.svg' : 'imgs/plus.svg'}
                                class="x_button_img second_img transform" alt="">
                            </button>
                        </div>

                        <div class="col-xs-1 col-md-0"></div>
                        <div class="col-12 col-md-7 col-lg-5 col-xl-5 second_description"
                             style="margin-bottom: 0px; padding-left: 0px;">
                            {#if show_third_page_descriptions.show_second_description}
                            {#if !is_phone}
                            <p class="font_26_16_v2 left_text align_text" transition:fade>
                                A platform to digitize all things around real estate:
                                Access management, floor plans, communication
                                between different stakeholders. Full transparency
                                for all parties involved.
                            </p>
                            {:else}
                            <p class="font_26_16_v2 left_text align_text" transition:slide>
                                A platform to digitize all things around real estate:
                                Access management, floor plans, communication
                                between different stakeholders. Full transparency
                                for all parties involved.
                            </p>
                            {/if}
                            {/if}
                        </div>
                        <div class="col-0 col-md-0 col-lg-1 col-xl-2"></div>

                    </div>

                    <!---------------------------------------------->

                    <div class="row" transition:fade>
                        <div class="col-0 col-md-0 col-lg-3"></div>
                        <div class="col-8 col-md-4 col-lg-3 col-xl-3 no_padding">
                            <p class="font_44_bold_34 text_left third_section pointer mb8 {night_theme ? 'yellow' : 'blue'}"
                               on:mouseover="{() => !is_phone && !show_third_page_descriptions.show_first_description
                                && !show_third_page_descriptions.show_second_description
                                && !show_third_page_descriptions.show_third_description ? mouselog('third_description', 'third_section', 'third_img') : null}"
                               on:mouseout="{() => !is_phone ? mouseover('third_description', 'third_section', 'third_img') : null}">
                                Education</p>
                        </div>
                        <div class="col-3 col-md-1 flexed_box">
                            <button class="plus_info auto_margin margin_top_plus_button x_button pointer"
                                    on:mouseover="{() => !is_phone && !show_third_page_descriptions.show_first_description
                                && !show_third_page_descriptions.show_second_description
                                && !show_third_page_descriptions.show_third_description ? mouselog('third_description', 'third_section', 'third_img') : null}"
                                    on:mouseout="{() => !is_phone ? mouseover('third_description', 'third_section', 'third_img') : null}">
                                <img src={night_theme ? 'imgs/night/show_mission/open.svg' : 'imgs/plus.svg'}
                                class="x_button_img third_img transform" alt="">
                            </button>
                        </div>

                        <div class="col-xs-1 col-md-0"></div>
                        <div class="col-12 col-md-7 col-lg-5 col-xl-5 third_description"
                             style="margin-bottom: 0px; padding-left: 0px">
                            {#if show_third_page_descriptions.show_third_description}
                            {#if !is_phone}
                            <p class="font_26_16_v2 text-left align_text" transition:fade>
                                Providing curated content for all age groups:
                                Starting with mentoring high school students, all
                                the way to providing content about complex
                                topics for professionals.
                            </p>
                            {:else}
                            <p class="font_26_16_v2 text-left align_text pb0" transition:slide>
                                Providing curated content for all age groups:
                                Starting with mentoring high school students, all
                                the way to providing content about complex
                                topics for professionals.
                            </p>
                            {/if}
                            {/if}
                        </div>
                        <div class="col-0 col-md-0 col-lg-1 col-xl-2"></div>

                    </div>
                </div>
            </div>
            <!--OUR MISSIONS-->
        </div>
    </div>
    {/if}
</div>
