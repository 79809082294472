<script>
  import {onMount} from 'svelte';
  import {fade} from 'svelte/transition';
  import Customer from './Custome.svelte'

  // export let is_phone
  export let start_video
  export let night_theme
  let video_el
  let show_alert = false

  const is_phone = window.matchMedia("(max-width: 768px)").matches
  onMount(() => {
    video_el = document.getElementById('vid')

  });
  $: if (start_video) {
    video_el.play();
    setTimeout(() => {
      show_alert = true
    }, 6500)
  } else {
    show_alert = false
  }


</script>
<style>

  .alert {
    width: 584px;
    height: 147px;
    /*background-color: white;*/
    position: absolute;
    z-index: 1;
    bottom: 49%;
    left: 26%;
    border-radius: 0;
    background-color: rgba(227, 226, 222, 0.8);

  }

  @media (max-width: 1280px) {
    .alert {
      bottom: 51%;
      left: 18%;
    }

    @media (device-height: 1024px) {
      .alert {
        bottom: 51%;
        left: 28%;
      }
    }
  }

  @media (min-width: 1440px) {
    .alert {
      bottom: 49%;
      left: 25%;
    }

    @media (device-height: 900px) {
      /*#vid {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        min-height: 14.3%;
        width: auto;
        height: auto;
      }*/

      .alert {
        bottom: 49%;
        left: 26%;
      }
    }
  }

  .address {
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 28px;
    font-feature-settings: 'liga' off;
    color: #213565;
  }

  .view_all_buttons {
    color: #213565;
    outline: none; /* Для синий ободки */
    border: 0;
    background: transparent;
    font-size: 26px;
  }

  .margin_auto {
    margin: auto;
  }

  .footer {
    background-color: #E5E5E5;
    width: 100%;
    height: 50px;
    /*position: fixed;*/
    display: flex;
    padding: 20px;
    left: 0;
    bottom: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 99999;
  }

  .footer_info {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
  }

  .pc_pad {
    padding-right: 40%
  }

  @media (min-width: 1280px) {
    @media (device-height: 1024px) {
      #vid {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        min-height: 14.3%;
        width: auto;
        height: 100vh;
      }

      .alert {
        bottom: 48%;
      }

    }
  }

  @media (max-width: 1024px) {
    .footer_info {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 20px;
      font-weight: normal;
    }
    .footer_info:last-child {
      margin-bottom: 0;
    }

    .footer {
      height: 80px;
      /*position: fixed;*/
      display: flex;
      padding: 20px 20px 10px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      /*min-height: 80px;*/
      z-index: 123;
    }

    .pc_pad {
      padding-right: 0px;
    }

    .alert {
      bottom: 50%;
      left: 18%;
      width: 500px;
    }

  }

  @media (width: 1024px) {
    .alert {
      width: auto;
      height: auto;
    }
  }

  @media (max-width: 550px) {
    .alert {
      bottom: 50%;
    }
  }

  @media screen and (max-width: 767px) {
    .alert {
      padding: 30px;
      bottom: 35%;
      width: 245px;
      height: 310px;
    }
  }
  .video-wrapper, .video-wrapper__mobile {
    width: 100vw;
  }
  .video-wrapper video, .video-wrapper__mobile video {
    object-fit: cover;
  }
  .video-wrapper {
    /*height: calc(100vh - 50px);*/
  }
  .video-wrapper video {
    /*height: calc(100vh - 50px);*/
    width: 100%;
  }
  .video-wrapper__mobile {
    /*height: calc(100vh - 110px);*/
    /*background: #000;*/
  }
  .video-wrapper__mobile video {
    /*width: 100%;*/
    height: calc(100vh - 110px);
  }
  .page-wrapper {
    display: grid;
    grid-template-rows: 1fr 50px;

    --footerHeight: 50px;
  }

  @media screen and (max-width: 1024px) {

    .video-wrapper, .video-wrapper video {
      /*height: calc(100vh - 80px);*/
    }
    .page-wrapper {
      /*grid-template-rows: calc(100vh - 80px) 80px*/
      --footerHeight: 80px;
    }
  }
  @media screen and (max-width: 767px) {
    #vid {
      /*position: relative;*/
      /*left: -200px;*/
    }
    .page-wrapper {
      --footerHeight: 110px;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 414px) and (min-height: 455px) and (max-height: 850px) {
    video {
      position: absolute;
      left: -50%;
    }
  }
</style>

{#if show_alert}
  <div class="alert">
    {#if !is_phone}
      <div class="row">
        <div class="col-md-5">
          <img src={night_theme ? 'imgs/night/show_mission/swarm_logo_svg.png' : 'imgs/logo/logo_2.png'}
               alt="">
        </div>
        <div class="col-md-7" style="padding-top: 10px">
          <p class="address">BAHNHOFSTRASSE 64<br>8001 ZÜRICH ROUTE</p>
          <a
                  href="https://www.google.com/maps/place/Bahnhofstrasse+64,+8001+Z%C3%BCrich,+%D0%A8%D0%B2%D0%B5%D0%B9%D1%86%D0%B0%D1%80%D0%B8%D1%8F/@47.3734572,8.538202,19z/data=!4m5!3m4!1s0x47900a06f03f824f:0xe1e24701d9fd18f2!8m2!3d47.3736069!4d8.5384933?hl=de"
                  class="view_all_buttons" style="font-size: 20px;line-height: 23px; font-weight: 400;">
            CALCULATE ROUTE <img src={night_theme ? 'imgs/night/two_arrows.svg' : 'imgs/two_arrows_blue.svg'}
                                 alt="" style="padding-bottom: 4px">
          </a>
        </div>
      </div>
    {:else}
      <div class="row">
        <div class="col-md-5">
          <img src={night_theme ? 'imgs/night/show_mission/swarm_logo_svg.png' : 'imgs/logo/logo_2.png'}
               alt="">
        </div>
        <div class="col-md-7" style="padding-top: 10px">
          <p class="address" style="font-size: 18px; line-height: 23px;">BAHNHOFSTRASSE 64<br>8001 ZÜRICH ROUTE</p>
          <a
                  href="https://www.google.com/maps/place/Bahnhofstrasse+64,+8001+Z%C3%BCrich,+%D0%A8%D0%B2%D0%B5%D0%B9%D1%86%D0%B0%D1%80%D0%B8%D1%8F/@47.3734572,8.538202,19z/data=!4m5!3m4!1s0x47900a06f03f824f:0xe1e24701d9fd18f2!8m2!3d47.3736069!4d8.5384933?hl=de"
                  class="view_all_buttons" style="font-size: 18px;line-height: 23px; font-weight: 400;">
            CALCULATE ROUTE <img src={night_theme ? 'imgs/night/two_arrows.svg' : 'imgs/two_arrows_blue.svg'}
                                 alt="" style="padding-bottom: 4px">
          </a>
          <a
                  href="{night_theme ? './contact-us_night.html' : './contact-us_day.html'}"
                  class="view_all_buttons"
                  style="display:flex; align-items: center; font-size: 18px; line-height: 23px; font-weight: 400; text-transform: uppercase; margin-top: 14px">
            Get in touch
            <img src="{night_theme ? './imgs/chatNight.svg' : './imgs/chatDay.svg'}"
                 alt="" style="margin-left: 4px">
          </a>
        </div>
      </div>
    {/if}
    <!--<div class="tail" transition:fade="{{ duration: 500 }}"></div>-->
  </div>
{/if}
<div class="page-wrapper" style={`grid-template-rows: calc(${window.innerHeight}px - var(--footerHeight)) var(--footerHeight)`}>
  <div class={is_phone ? 'video-wrapper__mobile' : 'video-wrapper'}>
    <video id="vid" width="auto" height="auto" autoplay muted playsinline style={`height: calc(${window.innerHeight}px - var(--footerHeight))`}>
      <source
              src={night_theme ?"/videos/video_night.mp4" : "/videos/video.mp4"}
              type="video/mp4">
    </video>
  </div>
<!--  <div class="col-md-12" style={is_phone ? 'height: calc(100vh - 70px - 80px);' : 'height: 100vh'}>
    <video id="vid" width="100%" height="auto" autoplay muted playsinline>
      <source
      src={night_theme ?"/videos/video_night.mp4" : "/videos/video.mp4"}
      type="video/mp4">
    </video>
  </div>-->

<!-- {#if is_phone} <div class="section">-->

  <!--</div>  {/if}-->
  <div class="footer">
    <p class="footer_info">© 2021 SWARM. All rights reserved</p>
    <p class="footer_info pc_pad">Terms & Conditions | Imprint</p>
    <!--<p>&nbsp;</p>-->
  </div>
<!--  <Customer/>-->
</div>
