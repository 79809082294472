<script>
    import {fade} from 'svelte/transition';
    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();
    export let animation_1;
    export let animation_2;
    export let animation_3;
    export let animation_4;
    export let animation_5;
    export let animation_6;
    export let animate
    export let is_phone
    // if (is_phone) {
    //     animation_1 = true
    //     animation_2 = true
    //     animation_3 = true
    //     animation_4 = true
    //     animation_5 = true
    //     animation_6 = true
    // }
    export let night_theme
</script>
<style>

    .content_block_values_low {
        min-height: 100px;
        /*padding-right: 0 !important;*/
    }

    .col-12, .col-11 {
        /*padding-right: 0 !important;*/
    }

    .back {
        background-color: #E9E9EB !important;
    }

    .view_all_button {
        position: absolute;
        top: 93%;
        left: 85%;
        color: #39A0C8;
        outline: none; /* Для синий ободки */
        border: 0;
        background: transparent;
    }

    .font_44_bold_34 {
        margin-bottom: 0;
    }

    .title_values {
        margin-bottom: 10px !important;
    }

    .values_number {
        /*margin-top: 22px;*/
        padding-right: 5px;
    }

    .font_44_bold_34 {
        margin-bottom: auto;
    }

    .svg-content {
        display: inline-block;
        position: absolute;
    }

    .content_block {
        padding-top: 0;
    }

    .fixed_center {
        margin: auto;
        width: 100%;
        /*height: 60%;*/
        padding-top: 3%;
    }

    .min-width {
        min-height: 512px;
    }

    .title_values {
        height: 30px;
        margin-bottom: 0;
        white-space: nowrap;
    }

    .flex_col {
        display: flex;
        flex-direction: column;
    }

    .col_items {
        margin-bottom: 4%;
        margin-top: 2%;
    }

    @media (min-width: 1300px) {
        .col_items {
            margin-bottom: 9%;
        }
    }

    .bird {
        position: absolute;
    }

    @media (max-width: 1024px) {
        @media (device-height: 1024px) {
            .font_22_12 {
                font-size: 24px;
                line-height: 33px;
            }

            .padding_on_sq {
                padding-left: 0;
            }

            .svg-content {
                height: 70% !important;
            }

            .padding_on_sq {
                padding-left: 15px;
            }
        }
        @media (max-height: 1023px) {
            .font_22_12 {
                font-size: 18px;
                line-height: 26px;
            }

            @media (min-width: 768px) {
                .svg-content {
                    height: 110% !important;
                }

                .col_items {
                    margin-bottom: 0;
                    margin-top: 0;
                }

            }

        }
    }

    @media (max-width: 1280px) {
        @media (max-height: 700px) {
            .font_22_12 {
                font-size: 16px;
                line-height: 24px;
            }

            .title_values {
                font-size: 32px;
            }

            @media (min-width: 490px) {
                .svg-content {
                    height: 110% !important;
                }
            }
        }
    }

    /*.font_22_12 {
        font-size: 18px;
        line-height: 27px;
    }*/


    .min-width {
        min-height: 712px;
    }


    .b_1 {
        left: 5%;
        top: -5%
    }

    .b_2 {
        left: 70%;
        top: -13%
    }

    .b_3 {
        left: 75%;
        top: 35%
    }

    .b_4 {
        left: 30%;
        top: 43%
    }

    .b_5 {
        left: 85%;
        top: 75%
    }

    .b_6 {
        left: 30%;
        top: -10%
    }

    .b_7 {
        left: 5%;
        top: 20%
    }

    .b_8 {
        left: 55%;
        top: 60%
    }

    .b_9 {
        left: 50%;
        top: 75%
    }

    .b_10 {
        left: 80%;
        top: 80%
    }

    .b_11 {
        left: 50%;
        top: -5%
    }

    .b_12 {
        left: 60%;
        top: 20%
    }

    .b_13 {
        left: 90%;
        top: 35%
    }

    .b_14 {
        left: 3%;
        top: 80%
    }

    .b_15 {
        left: 70%;
        top: 83%
    }

    .b_16 {
        left: 45%;
        top: 0%
    }

    .b_17 {
        left: 90%;
        top: 30%
    }

    .b_18 {
        left: 60%;
        top: 70%
    }

    .b_19 {
        left: 5%;
        top: 75%
    }

    .b_20 {
        left: 75%;
        top: 90%
    }

    .b_21 {
        left: 75%;
        top: -5%
    }

    .b_22 {
        left: 4%;
        top: 0%
    }

    .b_23 {
        left: 45%;
        top: 5%
    }

    .b_24 {
        left: 85%;
        top: 80%
    }

    .b_25 {
        left: 55%;
        top: 90%
    }

    .b_26 {
        left: 75%;
        top: -5%
    }

    .b_27 {
        left: 5%;
        top: 12%
    }

    .b_28 {
        left: 46%;
        top: -5%
    }

    .b_29 {
        left: 90%;
        top: 12%
    }

    .b_30 {
        left: 55%;
        top: 90%
    }

    .flex_container {
        display: flex;
        flex-direction: row;
        align-items: baseline;
    }

    .tabs_padding_desktop {
        padding-left: 126px
    }

    @media (min-width: 768px) {
        @media (max-device-height: 1366px) {
            .content_section {
                height: 50vh !important;
            }
        }
    }

    @media (width: 1440px) {
        .font_22_12 {
            font-size: 16px;
            line-height: 24px;
        }

        .pad_1440 {
            padding-right: 50px !important;
        }
    }

    .line_pad {
        display: inline-block;
        margin-bottom: 20px !important;
    }
    .w100 {
        width: 100%;
    }
    @media screen and (max-width: 768px) {
      .flex_container {
        margin-left: 6%;
      }
    }
    @media screen and (max-width: 568px) {
      .title_values {
        font-size: 30px;
      }
      .content_section.back {
        /* height: 170vh !important; */
      }
    }
    @media screen and (max-width: 568px) and (min-height: 680px) {
      .content_section.back {
        height: 110vh !important;
      }
    }
    @media screen and (max-width: 568px) and (max-height: 680px) {
      .content_section.back {
        height: 120vh !important;
      }
    }
    @media only screen and (min-width: 1024px) and (max-height: 1366px)
    and (-webkit-min-device-pixel-ratio: 1.5) and (hover: none) {
        .ipad_pro_pad{
            padding-left: 10%;
        }
    }
    @media screen and (max-width: 768px) {
      .line_pad {
        margin-bottom: 8px !important;
      }
      .title_values {
        font-size: 24px !important;
      }
      .font_22_12 {
        font-size: 14px !important;
        line-height: 21px !important;
        font-weight: normal !important;
        margin-bottom: 0 !important;
      }
      .values_number {
        font-size: 14px !important;
        padding-right: 10px !important;
      }
    }

    @media (max-width: 400px) {
        .title_values {
            font-size: 20px !important;
            margin-bottom: 0 !important;
        }
        .col_items {
            margin: 0;
        }
        .content_section.back {
            height: 140vh !important;
            padding-top: 20px !important;
        }
        .title_values {
            margin-top: 5px !important;
        }

        .font_22_12.left {
            font-size: 18px !important;
        }
    }
</style>
<div class="content_section back" style="display: flex; {is_phone ? 'height: 180vh; padding-top: 10%;' : ''}; ">
    <div class="{is_phone ? '' : 'fixed_center'} w100">
    <div class="content_block" style="padding-right: 3%;">
        <div class="row" style="color: #111A38;">
            <!--Values-->
            <div class="col-12 col-md-12">
                {#if animate}
                <div class="row" style="height: 70vh">
                    {#if !is_phone}
                    {#if animation_1 && !animation_2}
                    <img src="imgs/bird_values/first.svg" alt="" class="bird b_1"
                         transition:fade="{{ duration: 50 }}">
                    <img src="imgs/bird_values/second.svg" alt="" class="bird b_2"
                         transition:fade="{{ duration: 50 }}">
                    <img src="imgs/bird_values/third.svg" alt="" class="bird b_3"
                         transition:fade="{{ duration: 50 }}">
                    <img src="imgs/bird_values/fouth.svg" alt="" class="bird b_4"
                         transition:fade="{{ duration: 50 }}">
                    <img src="imgs/bird_values/five.svg" alt="" class="bird b_5"
                         transition:fade="{{ duration: 50 }}">
                    {/if}
                    {#if animation_2 && !animation_3}
                    <img src="imgs/bird_values/third.svg" alt="" class="bird b_6" transition:fade>
                    <img src="imgs/bird_values/fouth.svg" alt="" class="bird b_7" transition:fade>
                    <img src="imgs/bird_values/first.svg" alt="" class="bird b_8" transition:fade>
                    <img src="imgs/bird_values/second.svg" alt="" class="bird b_9" transition:fade>
                    <img src="imgs/bird_values/five.svg" alt="" class="bird b_10" transition:fade>
                    {/if}
                    {#if animation_3 && !animation_4}
                    <img src="imgs/bird_values/third.svg" alt="" class="bird b_11" transition:fade>
                    <img src="imgs/bird_values/five.svg" alt="" class="bird b_12" transition:fade>
                    <img src="imgs/bird_values/second.svg" alt="" class="bird b_13" transition:fade>
                    <img src="imgs/bird_values/first.svg" alt="" class="bird b_14" transition:fade>
                    <img src="imgs/bird_values/fouth.svg" alt="" class="bird b_15" transition:fade>
                    {/if}
                    {#if animation_4 && !animation_5}
                    <img src="imgs/bird_values/third.svg" alt="" class="bird b_16" transition:fade>
                    <img src="imgs/bird_values/five.svg" alt="" class="bird b_17" transition:fade>
                    <img src="imgs/bird_values/first.svg" alt="" class="bird b_18" transition:fade>
                    <img src="imgs/bird_values/second.svg" alt="" class="bird b_19" transition:fade>
                    <img src="imgs/bird_values/fouth.svg" alt="" class="bird b_20" transition:fade>
                    {/if}
                    {#if animation_5 && !animation_6}
                    <img src="imgs/bird_values/second.svg" alt="" class="bird b_21" transition:fade>
                    <img src="imgs/bird_values/first.svg" alt="" class="bird b_22" transition:fade>
                    <img src="imgs/bird_values/third.svg" alt="" class="bird b_23" transition:fade>
                    <img src="imgs/bird_values/five.svg" alt="" class="bird b_24" transition:fade>
                    <img src="imgs/bird_values/fouth.svg" alt="" class="bird b_25" transition:fade>
                    {/if}
                    {#if animation_6}
                    <img src="imgs/bird_values/five.svg" alt="" class="bird b_26" transition:fade>
                    <img src="imgs/bird_values/first.svg" alt="" class="bird b_27" transition:fade>
                    <img src="imgs/bird_values/third.svg" alt="" class="bird b_28" transition:fade>
                    <img src="imgs/bird_values/fouth.svg" alt="" class="bird b_29" transition:fade>
                    <img src="imgs/bird_values/second.svg" alt="" class="bird b_30" transition:fade>
                    {/if}
                    {/if}
                    <div class="col-12 col-lg-6 destop_padding min-width flex_col"
                         style="position: static; min-height: auto;">
                        <!--<div class="block" style="display: flex; position: absolute">-->
                        {#if night_theme}
                            <svg class="svg-content" width="144" height="610" viewBox="0 0 144 610" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M137.83 542.337C137.83 543.639 136.99 544.792 135.75 545.192L15.5162 583.949C13.5791 584.574 11.5957 583.129 11.5957 581.094V559.572C11.5957 558.258 12.4511 557.096 13.7063 556.707L72.1808 538.554L111.525 528.673C111.679 528.634 111.787 528.495 111.787 528.337C111.787 528.177 111.678 528.038 111.522 528L72.1808 518.479L13.7063 500.326C12.4511 499.936 11.5957 498.775 11.5957 497.461V476.854C11.5957 474.816 13.5851 473.371 15.5236 474.001L135.758 513.103C136.993 513.505 137.83 514.657 137.83 515.956V542.337Z" fill="#151F43"/>
                                <path d="M137.83 394.713C137.83 399.536 136.323 403.575 133.309 406.83C130.405 409.85 126.55 411.807 121.744 412.701C121.426 412.76 121.191 413.036 121.191 413.359C121.191 413.666 121.403 413.933 121.701 414.008C127.601 415.509 132.133 418.663 135.298 423.469C138.433 428.412 140 434.501 140 441.735C140 451.259 137.468 458.554 132.404 463.618C127.34 468.681 120.649 471.213 112.33 471.213C102.323 471.213 94.9078 467.596 90.0851 460.362C85.1419 453.008 82.6702 443.061 82.6702 430.522V418.511C82.6702 416.854 81.3271 415.511 79.6702 415.511H76.7021C72.1206 415.511 68.5035 416.717 65.8511 419.128C63.078 421.54 61.6915 425.579 61.6915 431.245C61.6915 436.55 62.8369 440.77 65.1277 443.905C66.7036 446.061 68.4221 447.961 70.2833 449.604C71.6222 450.786 71.8695 452.849 70.6848 454.185L60.549 465.621C59.5478 466.75 57.8557 466.969 56.6595 466.048C52.0123 462.473 48.3244 458.166 45.5957 453.128C42.3404 447.22 40.7128 439.263 40.7128 429.256C40.7128 415.752 43.6667 405.625 49.5745 398.873C55.4823 392.121 64.1028 388.745 75.4362 388.745H114.032C115.689 388.745 117.032 387.402 117.032 385.745V382.884C117.032 381.227 118.375 379.884 120.032 379.884H134.83C136.487 379.884 137.83 381.227 137.83 382.884V394.713ZM121.372 431.788C121.372 427.327 120.408 423.529 118.479 420.394C116.55 417.139 113.415 415.511 109.074 415.511H100.862C99.2048 415.511 97.8617 416.854 97.8617 418.511V428.533C97.8617 439.022 101.418 444.267 108.532 444.267H111.245C114.741 444.267 117.333 443.181 119.021 441.011C120.589 438.841 121.372 435.767 121.372 431.788Z" fill="#151F43"/>
                                <path d="M137.83 335.333C137.83 344.497 135.599 351.248 131.138 355.589C126.677 359.809 120.227 361.919 111.787 361.919H7C5.34314 361.919 4 360.575 4 358.919V338.153C4 336.496 5.34315 335.153 7 335.153H114.032C115.689 335.153 117.032 333.809 117.032 332.153V326.216C117.032 324.56 118.375 323.216 120.032 323.216H134.83C136.487 323.216 137.83 324.56 137.83 326.216V335.333Z" fill="#151F43"/>
                                <path d="M122.444 251.105C122.152 251.105 121.915 251.342 121.915 251.634C121.915 251.859 122.057 252.058 122.268 252.135C124.55 252.966 126.723 254.069 128.787 255.445C130.957 256.772 132.887 258.52 134.574 260.69C136.262 262.86 137.589 265.452 138.553 268.467C139.518 271.36 140 274.736 140 278.594C140 288.36 136.805 295.775 130.415 300.839C123.904 305.782 114.621 308.254 102.564 308.254H45.883C44.2261 308.254 42.883 306.911 42.883 305.254V284.488C42.883 282.831 44.2261 281.488 45.883 281.488H100.213C106 281.488 110.461 280.463 113.596 278.413C116.731 276.364 118.298 272.867 118.298 267.924C118.298 265.874 117.996 263.825 117.394 261.775C116.791 259.725 115.947 257.917 114.862 256.35C113.656 254.782 112.209 253.516 110.521 252.552C108.833 251.587 106.844 251.105 104.553 251.105H45.883C44.2261 251.105 42.883 249.762 42.883 248.105V227.339C42.883 225.682 44.2261 224.339 45.883 224.339H134.83C136.487 224.339 137.83 225.682 137.83 227.339V248.105C137.83 249.762 136.487 251.105 134.83 251.105H122.444Z" fill="#151F43"/>
                                <path d="M140 159.829C140 167.063 138.855 173.513 136.564 179.18C134.152 184.846 130.837 189.669 126.617 193.648C122.277 197.506 117.032 200.46 110.883 202.51C104.734 204.439 97.8617 205.403 90.266 205.403C82.7908 205.403 76.039 204.439 70.0106 202.51C63.8617 200.581 58.617 197.747 54.2766 194.01C49.9362 190.151 46.6206 185.449 44.3298 179.903C41.9184 174.357 40.7128 168.027 40.7128 160.914C40.7128 153.077 42.039 146.385 44.6915 140.839C47.344 135.173 50.9007 130.591 55.3617 127.095C59.8227 123.478 65.0071 120.885 70.9149 119.318C76.7021 117.63 82.7908 116.786 89.1809 116.786H94.1383C95.7952 116.786 97.1383 118.129 97.1383 119.786V176.286C97.1383 177.085 97.7861 177.733 98.5851 177.733C104.855 177.733 109.858 176.045 113.596 172.669C117.213 169.293 119.021 163.988 119.021 156.754C119.021 151.208 117.936 146.687 115.766 143.19C114.079 140.615 112.162 138.17 110.017 135.856C108.787 134.53 108.875 132.421 110.284 131.288L122.329 121.6C123.452 120.696 125.07 120.71 126.101 121.718C130.174 125.695 133.42 130.682 135.84 136.68C138.613 143.432 140 151.148 140 159.829ZM60.4255 160.371C60.4255 165.797 62.234 170.077 65.8511 173.212C69.3475 176.226 74.0496 177.733 79.9574 177.733C80.7565 177.733 81.4043 177.085 81.4043 176.286V146.084C81.4043 145.185 80.6755 144.456 79.7766 144.456C73.9894 144.456 69.3475 145.783 65.8511 148.435C62.234 150.967 60.4255 154.946 60.4255 160.371Z" fill="#151F43"/>
                                <path d="M140 65.6915C140 74.8546 138.553 82.5709 135.66 88.8405C133.038 94.293 129.505 99.244 125.059 103.693C123.931 104.823 122.11 104.78 120.975 103.658L109.343 92.1574C108.117 90.9457 108.171 88.9598 109.367 87.7191C112.046 84.941 114.239 81.9389 115.947 78.7128C118.117 74.6135 119.202 69.9716 119.202 64.7873C119.202 59.4823 118.418 55.805 116.851 53.7553C115.163 51.7057 112.933 50.6809 110.16 50.6809C105.699 50.6809 103.046 53.8759 102.202 60.266L100.936 70.5745C98.2837 91.9149 88.5177 102.585 71.6383 102.585C67.0567 102.585 62.8972 101.741 59.1596 100.053C55.3014 98.2447 52.0461 95.7731 49.3936 92.6383C46.6206 89.383 44.5106 85.5248 43.0638 81.0638C41.4965 76.4823 40.7128 71.3582 40.7128 65.6915C40.7128 60.7482 41.0745 56.4078 41.7979 52.6702C42.4007 48.8121 43.3652 45.4362 44.6915 42.5426C45.8972 39.5284 47.4645 36.8156 49.3936 34.4043C50.6445 32.653 52.0108 30.9306 53.4924 29.237C54.5942 27.9776 56.5218 27.9461 57.7187 29.1155L69.1057 40.2407C70.3005 41.4081 70.2997 43.3184 69.2264 44.5984C67.175 47.0449 65.5074 49.7355 64.2234 52.6702C62.4149 56.4078 61.5106 60.266 61.5106 64.2447C61.5106 68.8263 62.234 72.0816 63.6809 74.0107C65.1277 75.9397 67.0567 76.9043 69.4681 76.9043C72 76.9043 73.9894 76.2411 75.4362 74.9149C76.7624 73.4681 77.7269 70.8156 78.3298 66.9575L79.7766 56.2872C82.5496 35.4291 92.0745 25 108.351 25C112.933 25 117.152 25.9645 121.011 27.8936C124.869 29.8227 128.245 32.5957 131.138 36.2128C133.911 39.7092 136.082 43.9894 137.649 49.0532C139.216 53.9965 140 59.5426 140 65.6915Z" fill="#151F43"/>
                                <path d="M137.83 542.337C137.83 543.639 136.99 544.792 135.75 545.192L15.5162 583.949C13.5791 584.574 11.5957 583.129 11.5957 581.094V559.572C11.5957 558.258 12.4511 557.096 13.7063 556.707L72.1808 538.554L111.525 528.673C111.679 528.634 111.787 528.495 111.787 528.337C111.787 528.177 111.678 528.038 111.522 528L72.1808 518.479L13.7063 500.326C12.4511 499.936 11.5957 498.775 11.5957 497.461V476.854C11.5957 474.816 13.5851 473.371 15.5236 474.001L135.758 513.103C136.993 513.505 137.83 514.657 137.83 515.956V542.337Z" stroke="#151F43" stroke-width="7"/>
                                <path d="M137.83 394.713C137.83 399.536 136.323 403.575 133.309 406.83C130.405 409.85 126.55 411.807 121.744 412.701C121.426 412.76 121.191 413.036 121.191 413.359C121.191 413.666 121.403 413.933 121.701 414.008C127.601 415.509 132.133 418.663 135.298 423.469C138.433 428.412 140 434.501 140 441.735C140 451.259 137.468 458.554 132.404 463.618C127.34 468.681 120.649 471.213 112.33 471.213C102.323 471.213 94.9078 467.596 90.0851 460.362C85.1419 453.008 82.6702 443.061 82.6702 430.522V418.511C82.6702 416.854 81.3271 415.511 79.6702 415.511H76.7021C72.1206 415.511 68.5035 416.717 65.8511 419.128C63.078 421.54 61.6915 425.579 61.6915 431.245C61.6915 436.55 62.8369 440.77 65.1277 443.905C66.7036 446.061 68.4221 447.961 70.2833 449.604C71.6222 450.786 71.8695 452.849 70.6848 454.185L60.549 465.621C59.5478 466.75 57.8557 466.969 56.6595 466.048C52.0123 462.473 48.3244 458.166 45.5957 453.128C42.3404 447.22 40.7128 439.263 40.7128 429.256C40.7128 415.752 43.6667 405.625 49.5745 398.873C55.4823 392.121 64.1028 388.745 75.4362 388.745H114.032C115.689 388.745 117.032 387.402 117.032 385.745V382.884C117.032 381.227 118.375 379.884 120.032 379.884H134.83C136.487 379.884 137.83 381.227 137.83 382.884V394.713ZM121.372 431.788C121.372 427.327 120.408 423.529 118.479 420.394C116.55 417.139 113.415 415.511 109.074 415.511H100.862C99.2048 415.511 97.8617 416.854 97.8617 418.511V428.533C97.8617 439.022 101.418 444.267 108.532 444.267H111.245C114.741 444.267 117.333 443.181 119.021 441.011C120.589 438.841 121.372 435.767 121.372 431.788Z" stroke="#151F43" stroke-width="7"/>
                                <path d="M137.83 335.333C137.83 344.497 135.599 351.248 131.138 355.589C126.677 359.809 120.227 361.919 111.787 361.919H7C5.34314 361.919 4 360.575 4 358.919V338.153C4 336.496 5.34315 335.153 7 335.153H114.032C115.689 335.153 117.032 333.809 117.032 332.153V326.216C117.032 324.56 118.375 323.216 120.032 323.216H134.83C136.487 323.216 137.83 324.56 137.83 326.216V335.333Z" stroke="#151F43" stroke-width="7"/>
                                <path d="M122.444 251.105C122.152 251.105 121.915 251.342 121.915 251.634C121.915 251.859 122.057 252.058 122.268 252.135C124.55 252.966 126.723 254.069 128.787 255.445C130.957 256.772 132.887 258.52 134.574 260.69C136.262 262.86 137.589 265.452 138.553 268.467C139.518 271.36 140 274.736 140 278.594C140 288.36 136.805 295.775 130.415 300.839C123.904 305.782 114.621 308.254 102.564 308.254H45.883C44.2261 308.254 42.883 306.911 42.883 305.254V284.488C42.883 282.831 44.2261 281.488 45.883 281.488H100.213C106 281.488 110.461 280.463 113.596 278.413C116.731 276.364 118.298 272.867 118.298 267.924C118.298 265.874 117.996 263.825 117.394 261.775C116.791 259.725 115.947 257.917 114.862 256.35C113.656 254.782 112.209 253.516 110.521 252.552C108.833 251.587 106.844 251.105 104.553 251.105H45.883C44.2261 251.105 42.883 249.762 42.883 248.105V227.339C42.883 225.682 44.2261 224.339 45.883 224.339H134.83C136.487 224.339 137.83 225.682 137.83 227.339V248.105C137.83 249.762 136.487 251.105 134.83 251.105H122.444Z" stroke="#151F43" stroke-width="7"/>
                                <path d="M140 159.829C140 167.063 138.855 173.513 136.564 179.18C134.152 184.846 130.837 189.669 126.617 193.648C122.277 197.506 117.032 200.46 110.883 202.51C104.734 204.439 97.8617 205.403 90.266 205.403C82.7908 205.403 76.039 204.439 70.0106 202.51C63.8617 200.581 58.617 197.747 54.2766 194.01C49.9362 190.151 46.6206 185.449 44.3298 179.903C41.9184 174.357 40.7128 168.027 40.7128 160.914C40.7128 153.077 42.039 146.385 44.6915 140.839C47.344 135.173 50.9007 130.591 55.3617 127.095C59.8227 123.478 65.0071 120.885 70.9149 119.318C76.7021 117.63 82.7908 116.786 89.1809 116.786H94.1383C95.7952 116.786 97.1383 118.129 97.1383 119.786V176.286C97.1383 177.085 97.7861 177.733 98.5851 177.733C104.855 177.733 109.858 176.045 113.596 172.669C117.213 169.293 119.021 163.988 119.021 156.754C119.021 151.208 117.936 146.687 115.766 143.19C114.079 140.615 112.162 138.17 110.017 135.856C108.787 134.53 108.875 132.421 110.284 131.288L122.329 121.6C123.452 120.696 125.07 120.71 126.101 121.718C130.174 125.695 133.42 130.682 135.84 136.68C138.613 143.432 140 151.148 140 159.829ZM60.4255 160.371C60.4255 165.797 62.234 170.077 65.8511 173.212C69.3475 176.226 74.0496 177.733 79.9574 177.733C80.7565 177.733 81.4043 177.085 81.4043 176.286V146.084C81.4043 145.185 80.6755 144.456 79.7766 144.456C73.9894 144.456 69.3475 145.783 65.8511 148.435C62.234 150.967 60.4255 154.946 60.4255 160.371Z" stroke="#151F43" stroke-width="7"/>
                                <path d="M140 65.6915C140 74.8546 138.553 82.5709 135.66 88.8405C133.038 94.293 129.505 99.244 125.059 103.693C123.931 104.823 122.11 104.78 120.975 103.658L109.343 92.1574C108.117 90.9457 108.171 88.9598 109.367 87.7191C112.046 84.941 114.239 81.9389 115.947 78.7128C118.117 74.6135 119.202 69.9716 119.202 64.7873C119.202 59.4823 118.418 55.805 116.851 53.7553C115.163 51.7057 112.933 50.6809 110.16 50.6809C105.699 50.6809 103.046 53.8759 102.202 60.266L100.936 70.5745C98.2837 91.9149 88.5177 102.585 71.6383 102.585C67.0567 102.585 62.8972 101.741 59.1596 100.053C55.3014 98.2447 52.0461 95.7731 49.3936 92.6383C46.6206 89.383 44.5106 85.5248 43.0638 81.0638C41.4965 76.4823 40.7128 71.3582 40.7128 65.6915C40.7128 60.7482 41.0745 56.4078 41.7979 52.6702C42.4007 48.8121 43.3652 45.4362 44.6915 42.5426C45.8972 39.5284 47.4645 36.8156 49.3936 34.4043C50.6445 32.653 52.0108 30.9306 53.4924 29.237C54.5942 27.9776 56.5218 27.9461 57.7187 29.1155L69.1057 40.2407C70.3005 41.4081 70.2997 43.3184 69.2264 44.5984C67.175 47.0449 65.5074 49.7355 64.2234 52.6702C62.4149 56.4078 61.5106 60.266 61.5106 64.2447C61.5106 68.8263 62.234 72.0816 63.6809 74.0107C65.1277 75.9397 67.0567 76.9043 69.4681 76.9043C72 76.9043 73.9894 76.2411 75.4362 74.9149C76.7624 73.4681 77.7269 70.8156 78.3298 66.9575L79.7766 56.2872C82.5496 35.4291 92.0745 25 108.351 25C112.933 25 117.152 25.9645 121.011 27.8936C124.869 29.8227 128.245 32.5957 131.138 36.2128C133.911 39.7092 136.082 43.9894 137.649 49.0532C139.216 53.9965 140 59.5426 140 65.6915Z" stroke="#151F43" stroke-width="7"/>
                            </svg>

                        {:else}
                            <svg class="svg-content" width="144" height="610" viewBox="0 0 144 610" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M137.83 542.337C137.83 543.639 136.99 544.792 135.75 545.192L15.5162 583.949C13.5791 584.574 11.5957 583.129 11.5957 581.094V559.572C11.5957 558.258 12.4511 557.096 13.7063 556.707L72.1808 538.554L111.525 528.673C111.679 528.634 111.787 528.495 111.787 528.337C111.787 528.177 111.678 528.038 111.522 528L72.1808 518.479L13.7063 500.326C12.4511 499.936 11.5957 498.775 11.5957 497.461V476.854C11.5957 474.816 13.5851 473.371 15.5236 474.001L135.758 513.103C136.993 513.505 137.83 514.657 137.83 515.956V542.337Z" fill="#F6FBFC"/>
                                <path d="M137.83 394.713C137.83 399.536 136.323 403.575 133.309 406.83C130.405 409.85 126.55 411.807 121.744 412.701C121.426 412.76 121.191 413.036 121.191 413.359C121.191 413.666 121.403 413.933 121.701 414.008C127.601 415.509 132.133 418.663 135.298 423.469C138.433 428.412 140 434.501 140 441.735C140 451.259 137.468 458.554 132.404 463.618C127.34 468.681 120.649 471.213 112.33 471.213C102.323 471.213 94.9078 467.596 90.0851 460.362C85.1419 453.008 82.6702 443.061 82.6702 430.522V418.511C82.6702 416.854 81.3271 415.511 79.6702 415.511H76.7021C72.1206 415.511 68.5035 416.717 65.8511 419.128C63.078 421.54 61.6915 425.579 61.6915 431.245C61.6915 436.55 62.8369 440.77 65.1277 443.905C66.7036 446.061 68.4221 447.961 70.2833 449.604C71.6222 450.786 71.8695 452.849 70.6848 454.185L60.549 465.621C59.5478 466.75 57.8557 466.969 56.6595 466.048C52.0123 462.473 48.3244 458.166 45.5957 453.128C42.3404 447.22 40.7128 439.263 40.7128 429.256C40.7128 415.752 43.6667 405.625 49.5745 398.873C55.4823 392.121 64.1028 388.745 75.4362 388.745H114.032C115.689 388.745 117.032 387.402 117.032 385.745V382.884C117.032 381.227 118.375 379.884 120.032 379.884H134.83C136.487 379.884 137.83 381.227 137.83 382.884V394.713ZM121.372 431.788C121.372 427.327 120.408 423.529 118.479 420.394C116.55 417.139 113.415 415.511 109.074 415.511H100.862C99.2048 415.511 97.8617 416.854 97.8617 418.511V428.533C97.8617 439.022 101.418 444.267 108.532 444.267H111.245C114.741 444.267 117.333 443.181 119.021 441.011C120.589 438.841 121.372 435.767 121.372 431.788Z" fill="#F6FBFC"/>
                                <path d="M137.83 335.333C137.83 344.497 135.599 351.248 131.138 355.589C126.677 359.809 120.227 361.919 111.787 361.919H7C5.34314 361.919 4 360.575 4 358.919V338.153C4 336.496 5.34315 335.153 7 335.153H114.032C115.689 335.153 117.032 333.809 117.032 332.153V326.216C117.032 324.56 118.375 323.216 120.032 323.216H134.83C136.487 323.216 137.83 324.56 137.83 326.216V335.333Z" fill="#F6FBFC"/>
                                <path d="M122.444 251.105C122.152 251.105 121.915 251.342 121.915 251.634C121.915 251.859 122.057 252.058 122.268 252.135C124.55 252.966 126.723 254.069 128.787 255.445C130.957 256.772 132.887 258.52 134.574 260.69C136.262 262.86 137.589 265.452 138.553 268.467C139.518 271.36 140 274.736 140 278.594C140 288.36 136.805 295.775 130.415 300.839C123.904 305.782 114.621 308.254 102.564 308.254H45.883C44.2261 308.254 42.883 306.911 42.883 305.254V284.488C42.883 282.831 44.2261 281.488 45.883 281.488H100.213C106 281.488 110.461 280.463 113.596 278.413C116.731 276.364 118.298 272.867 118.298 267.924C118.298 265.874 117.996 263.825 117.394 261.775C116.791 259.725 115.947 257.917 114.862 256.35C113.656 254.782 112.209 253.516 110.521 252.552C108.833 251.587 106.844 251.105 104.553 251.105H45.883C44.2261 251.105 42.883 249.762 42.883 248.105V227.339C42.883 225.682 44.2261 224.339 45.883 224.339H134.83C136.487 224.339 137.83 225.682 137.83 227.339V248.105C137.83 249.762 136.487 251.105 134.83 251.105H122.444Z" fill="#F6FBFC"/>
                                <path d="M140 159.829C140 167.063 138.855 173.513 136.564 179.18C134.152 184.846 130.837 189.669 126.617 193.648C122.277 197.506 117.032 200.46 110.883 202.51C104.734 204.439 97.8617 205.403 90.266 205.403C82.7908 205.403 76.039 204.439 70.0106 202.51C63.8617 200.581 58.617 197.747 54.2766 194.01C49.9362 190.151 46.6206 185.449 44.3298 179.903C41.9184 174.357 40.7128 168.027 40.7128 160.914C40.7128 153.077 42.039 146.385 44.6915 140.839C47.344 135.173 50.9007 130.591 55.3617 127.095C59.8227 123.478 65.0071 120.885 70.9149 119.318C76.7021 117.63 82.7908 116.786 89.1809 116.786H94.1383C95.7952 116.786 97.1383 118.129 97.1383 119.786V176.286C97.1383 177.085 97.7861 177.733 98.5851 177.733C104.855 177.733 109.858 176.045 113.596 172.669C117.213 169.293 119.021 163.988 119.021 156.754C119.021 151.208 117.936 146.687 115.766 143.19C114.079 140.615 112.162 138.17 110.017 135.856C108.787 134.53 108.875 132.421 110.284 131.288L122.329 121.6C123.452 120.696 125.07 120.71 126.101 121.718C130.174 125.695 133.42 130.682 135.84 136.68C138.613 143.432 140 151.148 140 159.829ZM60.4255 160.371C60.4255 165.797 62.234 170.077 65.8511 173.212C69.3475 176.226 74.0496 177.733 79.9574 177.733C80.7565 177.733 81.4043 177.085 81.4043 176.286V146.084C81.4043 145.185 80.6755 144.456 79.7766 144.456C73.9894 144.456 69.3475 145.783 65.8511 148.435C62.234 150.967 60.4255 154.946 60.4255 160.371Z" fill="#F6FBFC"/>
                                <path d="M140 65.6915C140 74.8546 138.553 82.5709 135.66 88.8405C133.038 94.293 129.505 99.244 125.059 103.693C123.931 104.823 122.11 104.78 120.975 103.658L109.343 92.1574C108.117 90.9457 108.171 88.9598 109.367 87.7191C112.046 84.941 114.239 81.9389 115.947 78.7128C118.117 74.6135 119.202 69.9716 119.202 64.7873C119.202 59.4823 118.418 55.805 116.851 53.7553C115.163 51.7057 112.933 50.6809 110.16 50.6809C105.699 50.6809 103.046 53.8759 102.202 60.266L100.936 70.5745C98.2837 91.9149 88.5177 102.585 71.6383 102.585C67.0567 102.585 62.8972 101.741 59.1596 100.053C55.3014 98.2447 52.0461 95.7731 49.3936 92.6383C46.6206 89.383 44.5106 85.5248 43.0638 81.0638C41.4965 76.4823 40.7128 71.3582 40.7128 65.6915C40.7128 60.7482 41.0745 56.4078 41.7979 52.6702C42.4007 48.8121 43.3652 45.4362 44.6915 42.5426C45.8972 39.5284 47.4645 36.8156 49.3936 34.4043C50.6445 32.653 52.0108 30.9306 53.4924 29.237C54.5942 27.9776 56.5218 27.9461 57.7187 29.1155L69.1057 40.2407C70.3005 41.4081 70.2997 43.3184 69.2264 44.5984C67.175 47.0449 65.5074 49.7355 64.2234 52.6702C62.4149 56.4078 61.5106 60.266 61.5106 64.2447C61.5106 68.8263 62.234 72.0816 63.6809 74.0107C65.1277 75.9397 67.0567 76.9043 69.4681 76.9043C72 76.9043 73.9894 76.2411 75.4362 74.9149C76.7624 73.4681 77.7269 70.8156 78.3298 66.9575L79.7766 56.2872C82.5496 35.4291 92.0745 25 108.351 25C112.933 25 117.152 25.9645 121.011 27.8936C124.869 29.8227 128.245 32.5957 131.138 36.2128C133.911 39.7092 136.082 43.9894 137.649 49.0532C139.216 53.9965 140 59.5426 140 65.6915Z" fill="#F6FBFC"/>
                                <path d="M137.83 542.337C137.83 543.639 136.99 544.792 135.75 545.192L15.5162 583.949C13.5791 584.574 11.5957 583.129 11.5957 581.094V559.572C11.5957 558.258 12.4511 557.096 13.7063 556.707L72.1808 538.554L111.525 528.673C111.679 528.634 111.787 528.495 111.787 528.337C111.787 528.177 111.678 528.038 111.522 528L72.1808 518.479L13.7063 500.326C12.4511 499.936 11.5957 498.775 11.5957 497.461V476.854C11.5957 474.816 13.5851 473.371 15.5236 474.001L135.758 513.103C136.993 513.505 137.83 514.657 137.83 515.956V542.337Z" stroke="#F6FBFC" stroke-width="7"/>
                                <path d="M137.83 394.713C137.83 399.536 136.323 403.575 133.309 406.83C130.405 409.85 126.55 411.807 121.744 412.701C121.426 412.76 121.191 413.036 121.191 413.359C121.191 413.666 121.403 413.933 121.701 414.008C127.601 415.509 132.133 418.663 135.298 423.469C138.433 428.412 140 434.501 140 441.735C140 451.259 137.468 458.554 132.404 463.618C127.34 468.681 120.649 471.213 112.33 471.213C102.323 471.213 94.9078 467.596 90.0851 460.362C85.1419 453.008 82.6702 443.061 82.6702 430.522V418.511C82.6702 416.854 81.3271 415.511 79.6702 415.511H76.7021C72.1206 415.511 68.5035 416.717 65.8511 419.128C63.078 421.54 61.6915 425.579 61.6915 431.245C61.6915 436.55 62.8369 440.77 65.1277 443.905C66.7036 446.061 68.4221 447.961 70.2833 449.604C71.6222 450.786 71.8695 452.849 70.6848 454.185L60.549 465.621C59.5478 466.75 57.8557 466.969 56.6595 466.048C52.0123 462.473 48.3244 458.166 45.5957 453.128C42.3404 447.22 40.7128 439.263 40.7128 429.256C40.7128 415.752 43.6667 405.625 49.5745 398.873C55.4823 392.121 64.1028 388.745 75.4362 388.745H114.032C115.689 388.745 117.032 387.402 117.032 385.745V382.884C117.032 381.227 118.375 379.884 120.032 379.884H134.83C136.487 379.884 137.83 381.227 137.83 382.884V394.713ZM121.372 431.788C121.372 427.327 120.408 423.529 118.479 420.394C116.55 417.139 113.415 415.511 109.074 415.511H100.862C99.2048 415.511 97.8617 416.854 97.8617 418.511V428.533C97.8617 439.022 101.418 444.267 108.532 444.267H111.245C114.741 444.267 117.333 443.181 119.021 441.011C120.589 438.841 121.372 435.767 121.372 431.788Z" stroke="#F6FBFC" stroke-width="7"/>
                                <path d="M137.83 335.333C137.83 344.497 135.599 351.248 131.138 355.589C126.677 359.809 120.227 361.919 111.787 361.919H7C5.34314 361.919 4 360.575 4 358.919V338.153C4 336.496 5.34315 335.153 7 335.153H114.032C115.689 335.153 117.032 333.809 117.032 332.153V326.216C117.032 324.56 118.375 323.216 120.032 323.216H134.83C136.487 323.216 137.83 324.56 137.83 326.216V335.333Z" stroke="#F6FBFC" stroke-width="7"/>
                                <path d="M122.444 251.105C122.152 251.105 121.915 251.342 121.915 251.634C121.915 251.859 122.057 252.058 122.268 252.135C124.55 252.966 126.723 254.069 128.787 255.445C130.957 256.772 132.887 258.52 134.574 260.69C136.262 262.86 137.589 265.452 138.553 268.467C139.518 271.36 140 274.736 140 278.594C140 288.36 136.805 295.775 130.415 300.839C123.904 305.782 114.621 308.254 102.564 308.254H45.883C44.2261 308.254 42.883 306.911 42.883 305.254V284.488C42.883 282.831 44.2261 281.488 45.883 281.488H100.213C106 281.488 110.461 280.463 113.596 278.413C116.731 276.364 118.298 272.867 118.298 267.924C118.298 265.874 117.996 263.825 117.394 261.775C116.791 259.725 115.947 257.917 114.862 256.35C113.656 254.782 112.209 253.516 110.521 252.552C108.833 251.587 106.844 251.105 104.553 251.105H45.883C44.2261 251.105 42.883 249.762 42.883 248.105V227.339C42.883 225.682 44.2261 224.339 45.883 224.339H134.83C136.487 224.339 137.83 225.682 137.83 227.339V248.105C137.83 249.762 136.487 251.105 134.83 251.105H122.444Z" stroke="#F6FBFC" stroke-width="7"/>
                                <path d="M140 159.829C140 167.063 138.855 173.513 136.564 179.18C134.152 184.846 130.837 189.669 126.617 193.648C122.277 197.506 117.032 200.46 110.883 202.51C104.734 204.439 97.8617 205.403 90.266 205.403C82.7908 205.403 76.039 204.439 70.0106 202.51C63.8617 200.581 58.617 197.747 54.2766 194.01C49.9362 190.151 46.6206 185.449 44.3298 179.903C41.9184 174.357 40.7128 168.027 40.7128 160.914C40.7128 153.077 42.039 146.385 44.6915 140.839C47.344 135.173 50.9007 130.591 55.3617 127.095C59.8227 123.478 65.0071 120.885 70.9149 119.318C76.7021 117.63 82.7908 116.786 89.1809 116.786H94.1383C95.7952 116.786 97.1383 118.129 97.1383 119.786V176.286C97.1383 177.085 97.7861 177.733 98.5851 177.733C104.855 177.733 109.858 176.045 113.596 172.669C117.213 169.293 119.021 163.988 119.021 156.754C119.021 151.208 117.936 146.687 115.766 143.19C114.079 140.615 112.162 138.17 110.017 135.856C108.787 134.53 108.875 132.421 110.284 131.288L122.329 121.6C123.452 120.696 125.07 120.71 126.101 121.718C130.174 125.695 133.42 130.682 135.84 136.68C138.613 143.432 140 151.148 140 159.829ZM60.4255 160.371C60.4255 165.797 62.234 170.077 65.8511 173.212C69.3475 176.226 74.0496 177.733 79.9574 177.733C80.7565 177.733 81.4043 177.085 81.4043 176.286V146.084C81.4043 145.185 80.6755 144.456 79.7766 144.456C73.9894 144.456 69.3475 145.783 65.8511 148.435C62.234 150.967 60.4255 154.946 60.4255 160.371Z" stroke="#F6FBFC" stroke-width="7"/>
                                <path d="M140 65.6915C140 74.8546 138.553 82.5709 135.66 88.8405C133.038 94.293 129.505 99.244 125.059 103.693C123.931 104.823 122.11 104.78 120.975 103.658L109.343 92.1574C108.117 90.9457 108.171 88.9598 109.367 87.7191C112.046 84.941 114.239 81.9389 115.947 78.7128C118.117 74.6135 119.202 69.9716 119.202 64.7873C119.202 59.4823 118.418 55.805 116.851 53.7553C115.163 51.7057 112.933 50.6809 110.16 50.6809C105.699 50.6809 103.046 53.8759 102.202 60.266L100.936 70.5745C98.2837 91.9149 88.5177 102.585 71.6383 102.585C67.0567 102.585 62.8972 101.741 59.1596 100.053C55.3014 98.2447 52.0461 95.7731 49.3936 92.6383C46.6206 89.383 44.5106 85.5248 43.0638 81.0638C41.4965 76.4823 40.7128 71.3582 40.7128 65.6915C40.7128 60.7482 41.0745 56.4078 41.7979 52.6702C42.4007 48.8121 43.3652 45.4362 44.6915 42.5426C45.8972 39.5284 47.4645 36.8156 49.3936 34.4043C50.6445 32.653 52.0108 30.9306 53.4924 29.237C54.5942 27.9776 56.5218 27.9461 57.7187 29.1155L69.1057 40.2407C70.3005 41.4081 70.2997 43.3184 69.2264 44.5984C67.175 47.0449 65.5074 49.7355 64.2234 52.6702C62.4149 56.4078 61.5106 60.266 61.5106 64.2447C61.5106 68.8263 62.234 72.0816 63.6809 74.0107C65.1277 75.9397 67.0567 76.9043 69.4681 76.9043C72 76.9043 73.9894 76.2411 75.4362 74.9149C76.7624 73.4681 77.7269 70.8156 78.3298 66.9575L79.7766 56.2872C82.5496 35.4291 92.0745 25 108.351 25C112.933 25 117.152 25.9645 121.011 27.8936C124.869 29.8227 128.245 32.5957 131.138 36.2128C133.911 39.7092 136.082 43.9894 137.649 49.0532C139.216 53.9965 140 59.5426 140 65.6915Z" stroke="#F6FBFC" stroke-width="7"/>
                            </svg>
                        {/if}

                            <!--<svg width="180" height="100%" viewBox="-12 0 154 610" fill=""
                                 xmlns="http://www.w3.org/2000/svg" class="svg-content">
                                <g clip-path="url(#clip0)">
                                    <path d="M10.8603 490.476L7.77734 492.705V520.274L9.42668 522.517L71.4799 541.749L104.061 549.63L71.6068 557.763L9.42668 577.034L7.77734 579.276V607.77L10.8477 610L140.143 568.381L141.78 566.151V534.705L140.156 532.462L10.8603 490.476Z"
                                          fill={night_theme ? '#151F43' : '#F6FBFC'}/>
                                    <path d="M128.521 425.76C131.543 424.592 134.268 422.77 136.501 420.426C140.033 416.657 141.93 411.648 141.779 406.489V391.286L139.419 388.955H118.118L115.77 391.311V398.026H75.5139C63.2581 398.026 53.7553 401.827 47.2595 409.188C40.7636 416.549 37.6045 427.47 37.6045 441.799C37.6045 452.391 39.3553 460.956 42.8316 467.252C46.3133 473.565 51.2601 478.953 57.257 482.963L60.3273 482.582L74.8542 466.34L74.4609 462.869C71.5989 460.809 69.0884 458.302 67.0262 455.444C64.9962 452.657 63.9559 448.767 63.9559 443.877C63.9559 438.987 65.0977 435.224 67.3434 433.235C69.589 431.246 72.8496 430.131 76.9856 430.131H80.7918V443.142C80.7918 456.369 83.4307 467.05 88.6579 474.816C94.088 482.937 102.411 487.055 113.423 487.055C122.52 487.055 129.98 484.204 135.588 478.592C141.196 472.979 144.025 464.896 144.025 454.519C144.025 446.702 142.249 439.987 138.734 434.565C136.239 430.719 132.696 427.664 128.521 425.76ZM120.211 444.435C120.211 447.969 119.539 450.668 118.206 452.467C116.938 454.063 115.06 454.849 112.269 454.849H109.516C107.194 454.849 101.015 454.849 101.015 441.102V430.131H110.061C113.677 430.131 116.075 431.322 117.674 433.931C119.455 437.139 120.332 440.769 120.211 444.435Z"
                                          fill={night_theme ? '#151F43' : '#F6FBFC'}/>
                                    <path d="M134.193 361.69C139.268 356.875 141.805 349.362 141.805 339.366V326.975L139.445 324.631H118.118L115.771 326.975V336.832H2.34714L0 339.189V366.555L2.34714 368.911H112.726C121.988 368.911 129.194 366.479 134.193 361.69Z"
                                          fill={night_theme ? '#151F43' : '#F6FBFC'}/>
                                    <path d="M137.948 255.063C136.13 252.711 133.925 250.684 131.427 249.07H139.458L141.818 246.726V219.322L139.458 216.978H42.1723L39.8252 219.322V246.701L42.1723 249.045H105.304C107.027 248.999 108.732 249.4 110.252 250.21C111.683 250.999 112.928 252.083 113.906 253.391C114.956 254.854 115.745 256.487 116.24 258.218C116.78 260.044 117.058 261.938 117.065 263.843C117.065 268.1 115.796 270.963 113.259 272.61C110.455 274.447 106.281 275.372 100.838 275.372H42.1723L39.8252 277.729V305.095L42.1723 307.451H103.299C116.126 307.451 126.136 304.702 133.178 299.28C140.219 293.857 144 285.47 144 274.764C144.052 270.975 143.512 267.2 142.401 263.577C141.414 260.506 139.908 257.627 137.948 255.063Z"
                                          fill={night_theme ? '#151F43' : '#F6FBFC'}/>
                                    <path d="M127.012 104.334H124.017L106.928 117.979L106.763 121.488C109.741 124.357 112.369 127.568 114.591 131.054C116.776 134.907 117.827 139.299 117.623 143.723C117.623 150.4 115.986 155.303 112.777 158.268C109.567 161.232 105.469 162.677 100.089 162.816V102.788L97.6915 100.444H89.5971C83.0329 100.403 76.4938 101.255 70.1602 102.978C64.0939 104.616 58.4228 107.465 53.4892 111.353C48.5134 115.37 44.5358 120.482 41.8677 126.29C38.854 133.094 37.3852 140.479 37.5668 147.917C37.4557 154.886 38.7501 161.805 41.3729 168.264C43.7981 174.095 47.5258 179.295 52.2712 183.468C57.1897 187.686 62.9311 190.84 69.1326 192.729C76.1224 194.848 83.3966 195.882 90.7008 195.795C98.1068 195.866 105.478 194.78 112.548 192.577C118.792 190.649 124.544 187.394 129.41 183.037C134.103 178.716 137.781 173.411 140.181 167.504C142.799 160.924 144.092 153.894 143.987 146.815C144.129 138.387 142.625 130.013 139.559 122.16C136.877 115.294 132.572 109.178 127.012 104.334ZM79.2824 133.474V162.791C74.8763 162.872 70.5806 161.411 67.1407 158.661C64.0196 155.949 62.4971 152.326 62.4971 147.397C62.4971 142.469 63.9689 139.2 66.9884 136.983C70.6255 134.572 74.9181 133.337 79.2824 133.448V133.474Z"
                                          fill={night_theme ? '#151F43' : '#F6FBFC'}/>
                                    <path d="M46.9432 72.9511C49.8985 76.4444 53.5895 79.2434 57.7527 81.1483C62.1088 83.1109 66.8412 84.101 71.6198 84.0496C90.2066 84.0496 101.08 72.343 103.96 49.2464L105.228 38.7307C106.256 30.9643 109.732 30.9643 111.039 30.9643C111.981 30.9117 112.922 31.0896 113.78 31.4826C114.638 31.8757 115.387 32.4719 115.962 33.2195C116.875 34.3597 117.954 37.0203 117.954 43.0384C118.029 47.606 116.979 52.1221 114.896 56.1893C112.694 60.2701 109.791 63.9324 106.319 67.0091L106.243 70.4172L122.584 86.5202H125.82C131.43 81.4813 135.988 75.3843 139.23 68.5801C142.389 61.878 144 53.5794 144 43.8999C144.062 37.8681 143.206 31.862 141.463 26.0865C139.964 21.0381 137.537 16.3122 134.307 12.15C131.324 8.35717 127.543 5.26478 123.231 3.09133C118.869 0.982208 114.071 -0.0765539 109.225 -3.49053e-05C91.2723 -3.49053e-05 80.6404 11.4025 77.6336 34.0303L76.1238 44.9008C75.4133 49.3985 74.3222 51.0582 73.7259 51.6536C73.1359 52.1669 72.4495 52.5577 71.7068 52.8035C70.964 53.0493 70.1797 53.1451 69.3995 53.0853C68.6102 53.1135 67.8263 52.9439 67.1194 52.5921C66.4125 52.2402 65.805 51.7172 65.3523 51.0709C64.553 50.0066 63.6015 47.6501 63.6015 42.4809C63.6228 38.6962 64.4669 34.9613 66.0755 31.5344C67.7595 27.8975 70.0795 24.5897 72.9266 21.7662V18.4215L56.7884 2.69858L53.439 2.76192C51.1316 5.20226 48.963 7.76975 46.9432 10.4523C44.8973 13.1919 43.1932 16.17 41.8683 19.321C40.4116 22.8165 39.3643 26.4685 38.7472 30.2041C38.0049 34.7693 37.6526 39.3892 37.6942 44.0139C37.619 49.5656 38.4416 55.093 40.1301 60.383C41.5786 64.9675 43.8911 69.2334 46.9432 72.9511Z"
                                          fill={night_theme ? '#151F43' : '#F6FBFC'}/>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="144" height="610" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>-->
                        <!-- </div> -->
                        <div style="height: 100%; padding-left: 33px">
                            {#if animation_1}
                            <div class="row col_items" transition:fade>
                                <div class="col-12 content_block_values_low tabs_padding_desktop">
                                    <div class="wow fadeInLeft" data-wow-duration="1s">
                                        <div class="flex_container">

                                            <p class="values_number title_values" style="margin-bottom: 0">
                                                01</p>
                                            <div>
                                                <p class="font_44_bold_34 title_values line_pad">
                                                    Robustness</p>
                                                <p class="font_22_12 left" >Tasks are completed even
                                                    if some individuals are removed.</p>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                                <!--<div class="col-1"></div>-->
                            </div>
                            {/if}
                            {#if animation_2}
                            <div class="row col_items" transition:fade>
                                <div class="col-12 content_block_values tabs_padding_desktop">
                                    <div class="wow-disable wow fadeInLeft" data-wow-duration="1.2s">
                                        <div class="flex_container">
                                            <p class="values_number title_values">
                                                02</p>
                                            <div><p class="font_44_bold_34 title_values line_pad"
                                            >Collective
                                                Strength</p>
                                                <p class="font_22_12 left">Behavior or
                                                    actions of a
                                                    system working towards a common goal. When individuals engage in
                                                    collective
                                                    action, the strength of the group‘s resources is combined with the
                                                    knowledge
                                                    and
                                                    efforts in order to reach a goal shared by all participants.</p>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <!--<div class="col-1"></div>-->
                            </div>
                            {/if}
                            {#if animation_3}
                            <div class="row" transition:fade>
                                <div class="col-12 content_block_values tabs_padding_desktop">
                                    <div class="fadeInLeft wow" data-wow-duration="1.4s">
                                        <div class="flex_container">
                                            <p class="values_number title_values">
                                                03</p>
                                            <div>
                                                <p class="font_44_bold_34 title_values line_pad">
                                                    Decentralization</p>
                                                <p class="font_22_12 left">Each individual has a
                                                    simple job to do and performs it without supervision.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--<div class="col-1"></div>-->
                            </div>
                            {/if}
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 flex_col ipad_pro_pad" style="min-height: 394px">
                        <div class="row">
                            <div class="col-xl-1 col-1 col-md-1"></div>
                            <div class="col-xl-10 col-11 col-md-11 padding_on_sq pad_1440">
                                {#if animation_4}
                                <div class="row col_items" transition:fade>
                                    <div class="col-11 content_block_values">
                                        <div class="wow fadeInLeft" data-wow-duration="1.6s">
                                            <div class="flex_container">
                                                <p class="values_number title_values">
                                                    04</p>
                                                <div>
                                                    <p class="font_44_bold_34 title_values line_pad">Transparency</p>
                                                    <p class="font_22_12 left_plus">Good governance is
                                                        guided
                                                        by openness, honesty and accountability.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-1"></div>
                                </div>
                                {/if}
                                {#if animation_5}
                                <div class="row col_items" transition:fade>
                                    <div class="col-11 content_block_values">
                                        <div class="wow fadeInLeft" data-wow-duration="1.8s">
                                            <div class="flex_container">
                                                <p class="values_number title_values">
                                                    05</p>

                                                <div>
                                                    <p class="font_44_bold_34 title_values line_pad">
                                                        Self-organization</p>
                                                    <p class="font_22_12 left_plus">Methods of problem
                                                        solving are not prescribed from a central authority, but rather
                                                        developed by the
                                                        individuals who are responsible for completing the task.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-1"></div>
                                </div>
                                {/if}
                                {#if animation_6}
                                <div class="row" transition:fade>
                                    <div class="col-11 content_block_values_low">
                                        <div class="wow fadeInLeft" data-wow-duration="2s">
                                            <div class="flex_container">
                                                <p class="values_number title_values">
                                                    06</p>

                                                <div>
                                                    <p class="font_44_bold_34 title_values line_pad">Flexibility</p>
                                                    <p class="font_22_12 left_plus">Adaptable to
                                                        change.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-1"></div>
                                </div>
                                {/if}
                            </div>

                        </div>
                    </div>
                </div>
                {/if}
            </div>
            <!--Values-->
        </div>
        {#if animate && !is_phone}
        <button class="view_all_button" transition:fade on:click="{() => dispatch('show_all', 'detail value')}">
            VIEW ALL<img src={night_theme ? 'imgs/night/two_arrows_yellow.svg' : 'imgs/arrows.svg'} alt="" style="padding-bottom: 4px">
        </button>
        {/if}
    </div>
</div>
</div>

