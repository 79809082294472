<script>
    import {fade} from 'svelte/transition';
    import {onMount} from 'svelte'

    const is_phone = window.matchMedia("(max-width: 768px)").matches

    export let animate;
    export let night_theme;
</script>
<style>
    .second_part {
        /*margin-left: 8.2%;*/
        /*margin-top: 28.5%;*/
        width: 724px;
        height: 404px;
        margin: 0 auto;
    }

    @media (min-width: 1441px) {
        .second_part {
            /*margin-left: 300px;*/
        }
    }

    @media (max-width: 1280px) {
        .second_part {
            width: 654px;
            height: 363px;
        }

        .font_26_16 {
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.34px;
        }

        @media (max-height: 786px) {
            .second_part {
                /*margin-top: 15%;*/
            }
        }
    }

    .r1 {
        padding: 3% 3.1% 3.1% 3.1%;
    }

    .logo-img {
        display: inline;
        padding-bottom: 3.5%;
        vertical-align: unset;
        width: 216px;
        padding-bottom: 2.5%;
        margin-left: -2px;
    }

    .section_two_what {
        font-size: 38px;
    }

    .second_part_body {
        margin-bottom: 10%;
        font-weight: 300;
        color: #101b38;
        margin-top: -7px;
    }

    @media (max-width: 1023px) {
        .font_26_16 {
            font-size: 22px;
            line-height: 34px;
        }

        .second_part {
            width: 100%;
            /*height: 100vh;*/
            margin-left: 0px;
            margin-top: 0px;
            border: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .second_part_line_block {
            margin-bottom: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    @media (max-width: 540px) {
        .font_26_16 {
            font-size: 16px;
            line-height: 23px;
        }

        .second_part {
            /*background: red;*/
            /*width: 100%;*/
            /*height: 100vh;*/
            /*margin-left: 0px;*/
            /*margin-top: 0px;*/
            /*border: none;*/
            /*display: flex;*/
            /*flex-direction: column;*/
            /*align-items: center;*/
            /*justify-content: center;*/
        }

        .left_text {
            margin-top: 32px;
            /*padding-left: 19px;*/
        }
    }

    @media (width: 1024px) {
        .second_part {
            margin-left: 0;
        }
    }

    @media (width: 768px) {
        .second_part_line_block {
            width: 100%;
            margin-left: 0px;
            margin-top: 0px;
            border: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    @media (min-width: 1440px) {
        .second_part {
            margin-top: -10%;
        }
    }

    @media (max-width: 768px) {
        .second_part_body {
            margin-left: 8px;
            margin-right: 4px;
        }

        .r1 {
            padding: 0 3.1% 3.1% 3.1%;
        }
    }
</style>
<div class="row">
    <div class="col-12">
        <!--ABOUT-->
        <div class="row">
            <div class="col-0 col-md-2  col-lg-4 col-xl-4"></div>
            <div class="col-12 col-md-8 col-lg-8 col-xl-8">
                {#if animate}
                    <div class="second_part"  transition:fade="{{ duration: 100 }}" style="background-color: #E9EAED; {`height: ${is_phone ? (window.innerHeight + 'px') : 'auto'}`}">
                        <div class="row r1">
                            <div class="col-2 col-sm-0"></div>
                            <div class="col-sm-12 col-8">
                                <div class="wow fadeIn" data-wow-duration="2s">
                                    <div class="second_part_line_block mobile_padding">

                                        <p class="font_44_bold_34 center_text section_two_what "
                                           style="display: inline; vertical-align: bottom;">What‘s &nbsp;</p>
                                        <img class="logo-img"
                                             src={ night_theme ? 'imgs/night/swarm_logo_svg.png' : 'imgs/logo/swarm_logo.png'}
                                             alt="">
                                    </div>
                                    <p class="font_26_16 left_text mobile_padding second_part_body">
                                        A platform with different IT aplications in the
                                        pension fund, education, and real estate space.
                                        All with one goal: leveraging the collective
                                        intelligence and empowering the end user.
                                    </p>
                                </div>
                            </div>
                            <div class="col-2 col-sm-0"></div>
                        </div>
                    </div>

                {/if}
                <div class="col-0 col-md-2"></div>
            </div>
        </div>
        <!--ABOUT-->
    </div>
</div>
