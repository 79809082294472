<script>
    export let placeholder
    export let width
    let value = '';
    //{placeholder}
    let new_placeholder;
    function focusedInput (){
        new_placeholder.classList.add('placeholder_up')
    }
    function noFocusedInput() {
        if (value === '' || null){
            new_placeholder.classList.remove('placeholder_up')
        }
    }
</script>
<style>
    input{
        border: 0;
        border-bottom: 1px solid #111A38;
        background-color: rgba(0,0,0,0);
        outline: 0;
        border-radius: 0px;

    }
    .placeholder {
        font-size: 16px;
        line-height: 36px;
        color: #111A38;
        position: absolute;
        box-sizing: content-box;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        pointer-events: none;
        font-weight: normal;
        padding-top: 12px;
        transition: 0.5s;

    }

    .parent{
        height: 50px;
        display: flex;
        margin-right: 0px;
    }
    input {
        margin-top: auto;
    }
    .placeholder_up {
        padding-top: 0;
        color: #AEB1BC;
        font-size: 10px;
        line-height: 13px;
    }
</style>
<div class="placeholder_up"></div>
<div class="parent">
    <input  bind:value style="width: {width};" on:focus={()=>{focusedInput()}} on:blur={()=>{noFocusedInput()}}>
    <span class="placeholder" bind:this={new_placeholder}>{placeholder}</span>
</div>
