<script>
    import {createEventDispatcher, onDestroy} from 'svelte';
    import Close from "../Buttons/Back.svelte"
    import SendMessage from "../Buttons/SendMessage.svelte"
    import Input from "../Imputs/imput.svelte"

    let modal;
    let modal_back
    const dispatch = createEventDispatcher();
    const close = () => dispatch('close');
</script>
<!--<div class="modal-background" on:click={close} bind:this={modal_back}></div>-->
<div class="modal_customer" role="dialog" aria-modal="true" bind:this={modal} style="transition: 10s;">
    <div class="content_sec">
        <Close on:close={()=>{close()}} />
            <p class="font_bold_36_26">Get in touch with us. We’re looking forward.</p>
            <div class="input_padding">
                <Input placeholder="Name" width="100%"/>
            </div>
            <div class="input_padding">
                <Input placeholder="E-mail" width="100%"/>
            </div>
            <div style="padding-top: 8%">
                <div class="block" style="width: 83%"><Input placeholder="Message" width="100%"/></div>
                <div class="block"><SendMessage/></div>
            </div>
    </div>
    <div class="modal_footer">
        <img src="imgs/logo/white_logo.svg" alt="" class="centered_items">
        <p class="site centered_items">info@swarm-xxx.com </p>
        <p></p>
    </div>
</div>


<style>
    .input_padding {
        margin-top: 8%;
    }
    .for_button{
        width: 50px;
    }
    .block {
        display: inline-block;
    }
    .site {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 37px;
        display: flex;
        align-items: flex-end;
        font-feature-settings: 'liga' off;
        color: #AEB1BC;
    }
    .centered_items{
        margin: auto;
    }
    .modal_footer {
        background-color: #213565;
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        width: 100%;
        min-height: 27%;

    }
    .modal-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
    }

    .modal_customer {
        position: absolute;
        width: 480px;
        height: 100vh;
        overflow: auto;
        background: white;
        right: 0 !important;
        z-index: 10000;
        top: 0;
        transition: 1s;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    }

    button {
        display: block;
    }

    .content_sec {
        padding: 40px;

    }
    @media (width: 1024px) {
        .modal_footer {
            margin-bottom: 10%;
        }
        .input_padding {
            margin-top: 2%;
        }
    }
    @media (width: 1280px) {
        @media (max-height: 700px) {
            .input_padding {
                margin-top: 3%;
            }
        }
    }
</style>
