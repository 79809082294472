export function DrawLines(id) {
    const blocks = [['block_1', 'bird_1'], ['block_2', 'bird_2'], ['block_3', 'bird_3'], ['block_4', 'bird_4']]
    DrawLine(blocks[id], id)
}

const DrawLine = (currentValue, index, array) => {
    let first_el,
        second_el,
        first_point,
        second_point;
    /**
     *Find two elements between which there will be a line
     */
    first_el = window.jq('#' + currentValue[0]);
    second_el = window.jq('#' + currentValue[1]);
    /**
     * Find first point position
     */
    first_point = first_el.offset();
    first_point.left = first_point.left + (first_el.width() / 45);
    first_point.top = first_point.top + first_el.height() + 10;
    /**
     * Find second point position
     */
    second_point = second_el.offset();
    second_point.left = second_point.left + (second_el.width() / 2);
    second_point.top = second_point.top - 10
    if (currentValue[1] === 'bird_3'){
        second_point.top = second_point.top + 5
    }
    /**
     * Calculation height and width for svg element
     * @type {number}
     */
    let width = second_point.left - first_point.left,
        height = Math.abs(second_point.top - first_point.top),
        svgTop = first_point.top,
        svgLeft = first_point.left,
        x1 = 0,
        x2 = width;
    if (width < 0) {
        width = Math.abs(width);
        svgLeft = second_point.left;
        x1 = width;
        x2 = 0
    }

    const svg = `<svg width="${width}" height="${height}" style="position: absolute; top: ${svgTop}px; left: ${svgLeft}px"  class="customer_line">
<line x1="${x1}" y1="0" x2="${x2}" y2="${height}" stroke="#111A38"/></svg>`

    window.jq("body").append(svg)
}