<script>
  import Navbar from "./Components/Navbar.svelte";
  import Bats from "./Components/BatsBackground.svelte";
  import Sidebar from "./Components/Sidebar.svelte";
  import SectionOne from "./Components/Sections/SectionOne.svelte";
  import SectionTwo from "./Components/Sections/SectionTwo.svelte";
  import SectionThree from "./Components/Sections/SectionThree.svelte";
  import SectionFour from "./Components/Sections/SectionFour.svelte";
  import SectionFive_1, {fullpage} from "./Components/Sections/SectionFive_1.svelte";
  import SectionFive from "./Components/Sections/SectionFive.svelte";
  import SectionSix from "./Components/Sections/SectionSix_1.svelte";
  import Customer from "./Components/Sections/Custome.svelte";
  import { DrawLines } from "./Components/Helpers/DrawLine.js";
  import { DrawWhiteLines } from "./Components/Helpers/DrawWhiteLine.js";
  //import {DrawLinesMobile} from "./Components/Helpers/DrawLineMobile.js"
  import { DrawZipLine } from "./Components/Helpers/DrowZipLine.js";
  import { DrawZipLineMobile } from "./Components/Helpers/DrowZipLineMobile.js";
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import Modal from "./Components/Sections/PopUp.svelte";
  import { slide } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  let c_timer = true;
  let night_theme = false;
  let it_phone = false;
  let width_1;
  let height_1;
  let close_modal = false;
  let showModal = true;
  let show_logo = false;
  let padding_for_nav = true;
  let animation_interval;
  let start_first_page_animation = it_phone;
  let start_second_page_animation = it_phone;
  let start_third_page_animation = it_phone;
  let start_six_page_animation = it_phone;
  let show_first_section_mission = false;
  let show_second_section_mission = false;
  let show_third_section_mission = false;
  let show_third_page_descriptions = {
    show_first_description: true,
    show_second_description: true,
    show_third_description: true,
  };
  let start_third_animation = true;
  let start_fourth_page_animation = {
    animation_1: false,
    animation_2: false,
    animation_3: false,
    animation_4: false,
    animation_5: false,
    animation_6: false,
  };
  let general_fourth_page_animation = false;
  let start_five_page_animation = false;
  let bird = {
    first_bird_active: false,
    second_bird_active: false,
    third_bird_active: false,
    fourth_bird_active: false,
    fifth_bird_active: false,
  };
  let customer_info = {
    first_customer_section: false,
    second_customer_section: false,
    third_customer_section: false,
    fourth_customer_section: false,
    first_customer_section_mobile: false,
    second_customer_section_mobile: false,
    third_customer_section_mobile: false,
    fourth_customer_section_mobile: false,
  };
  let time_step = 500;
  let show_p_missions = false;
  let start_video = false;
  let monitor_width = screen.width;
  let monitor_height = screen.height;
  let recolor_nav = false;
  let animated = false;
  let birds_puck = {
    between_first_bird: false,
    between_second_bird: false,
    between_third_bird: false,
    between_forth_bird: false,
    between_five_bird: false,
    between_six_bird: false,
    between_seven_bird: false,
    between_eight_bird: false,
    between_nine_bird: false,
    between_ten_bird: false,
    between_eleven_bird: false,
    between_twelve_bird: false,
  };
  let show_all = false;
  let show_description = false;
  let test_animate = false;
  let stop_third_animation = false;
  let stop_fourth_animation = false;
  let stop_fifth_animation = false;
  let tooltip_parent = document.querySelector(".custom-tooltip");
  let tooltip = document.querySelector(".custom-tooltip_text");

  let fullpageInstance = null

  let resizeHandlerTimout = null;
  window.addEventListener("resize", isItPhone);

  function callbackFunction(event) {
    for (let key in start_fourth_page_animation) {
      start_fourth_page_animation[key] = true;
    }
  }

  function dis_modal() {
    document.querySelector(".modal_parent").classList.add("disable_modal");
  }

  function callbackFunctionCustomer(event) {
    for (let key in customer_info) {
      customer_info[key] = true;
    }
  }

  function getScreen() {
    width_1 = screen.width;
    height_1 = screen.height;
  }

  function getPartOfDay() {
    let now = new Date().getHours();
    if (now >= 8 && now < 20) {
      night_theme = false;
      document.body.classList.remove("night_theme");
      //document.body.classList.add('night_theme')
    } else if (now >= 20 || now < 8) {
      night_theme = true;
      document.body.classList.add("night_theme");
      // document.body.classList.remove('night_theme')
    }
  }

  function isItPhone() {
    it_phone = window.matchMedia("(max-width: 768px)").matches;
  }

  function sixPageRecolor() {
    document.querySelectorAll(".fp-tooltip")[6].style.color = "white";
    document.querySelectorAll(".fp-sr-only")[6].style.backgroundImage =
      "url(imgs/white_dote.svg)";
    recolor_nav = true;
  }

  function preload() {
    test_animate ? (test_animate = false) : (test_animate = true);
    document.location("/#about");
  }
  function initFifthPage() {
    if (!stop_fifth_animation) {
      show_logo = true;
      let first_animation_time = it_phone ? 500 : 2000;
      let second_animation_time = it_phone ? 1500 : 10000;
      let third_animation_time = it_phone ? 2500 : 15000;
      let fourth_animation_time = it_phone ? 3500 : 19000;

      const timeline = {
        2000: () => {
          c_timer = false;
        },

        [first_animation_time]: () => {
          bird.fifth_bird_active = false;
          customer_info.first_customer_section = true;
          customer_info.first_customer_section_mobile = true;
        },
        3500: () => {
          if (!it_phone) {
            !night_theme ? DrawLines(0) : DrawWhiteLines(0);
          }
        },
        8000: () => {
          if (!show_all) {
            jq(".customer_line").remove();
          }
          customer_info.first_customer_section = false;
          birds_puck.between_first_bird = true;
        },
        8500: () => {
          birds_puck.between_first_bird = true;
        },
        9000: () => {
          birds_puck.between_first_bird = false;
          birds_puck.between_second_bird = true;
        },
        9500: () => {
          birds_puck.between_second_bird = false;
          birds_puck.between_third_bird = true;
        },
        9800: () => {
          birds_puck.between_third_bird = false;
          birds_puck.between_forth_bird = true;
        },
        [second_animation_time]: () => {
          birds_puck.between_forth_bird = false;
          customer_info.second_customer_section = true;
          customer_info.second_customer_section_mobile = true;
        },
        11200: () => {
          if (!it_phone) {
            !night_theme ? DrawLines(1) : DrawWhiteLines(1);
          }
        },
        13000: () => {
          if (!show_all) {
            jq(".customer_line").remove();
          }
          birds_puck.between_eight_bird = false;
          customer_info.second_customer_section = false;
        },
        13300: () => {
          birds_puck.between_five_bird = true;
        },
        13700: () => {
          birds_puck.between_five_bird = false;
          birds_puck.between_six_bird = true;
        },
        14300: () => {
          birds_puck.between_six_bird = false;
          birds_puck.between_seven_bird = true;
        },
        14700: () => {
          birds_puck.between_seven_bird = false;
          birds_puck.between_eight_bird = true;
        },
        [third_animation_time]: () => {
          birds_puck.between_eight_bird = false;
          customer_info.third_customer_section = true;
          customer_info.third_customer_section_mobile = true;
        },
        16000: () => {
          if (!it_phone) {
            !night_theme ? DrawLines(2) : DrawWhiteLines(2);
          }
        },
        17000: () => {
          if (!show_all) {
            jq(".customer_line").remove();
          }
          customer_info.third_customer_section = false;
        },
        17300: () => {
          birds_puck.between_nine_bird = true;
        },
        17800: () => {
          birds_puck.between_nine_bird = false;
          birds_puck.between_ten_bird = true;
        },
        18200: () => {
          birds_puck.between_ten_bird = false;
          birds_puck.between_eleven_bird = true;
        },
        18700: () => {
          birds_puck.between_eleven_bird = false;
          birds_puck.between_twelve_bird = true;
        },
        [fourth_animation_time]: () => {
          birds_puck.between_twelve_bird = false;
          customer_info.fourth_customer_section = true;
          customer_info.fourth_customer_section_mobile = true;
        },
        20300: () => {
          if (!it_phone) {
            !night_theme ? DrawLines(3) : DrawWhiteLines(3);
          }
        },
        22000: () => {
          customer_info.fourth_customer_section = false;
          jq(".customer_line").remove();
        },
        24000: () => {
          show_all = true;
        },
        26000: () => {
          show_description = true;
        },
        27200: () => {
          DrawZipLine(0);
          DrawZipLine(1);
          DrawZipLine(2);
          if (!it_phone) {
            if (!night_theme) {
              DrawLines(0);
              DrawLines(1);
              DrawLines(2);
              DrawLines(3);
            } else {
              DrawWhiteLines(0);
              DrawWhiteLines(1);
              DrawWhiteLines(2);
              DrawWhiteLines(3);
            }
          }
        },
      };

      let max_time = 28000,
        step = 0;

      const runStep = () => {
        step = step + 100;
        if (timeline[step]) timeline[step]();
        if (step > max_time) clearInterval(animation_interval);
      };
      animation_interval = setInterval(runStep, 100);
      setTimeout(() => {
        c_timer = false;
        show_logo = true;
      }, 2000);
      show_logo = true;
      for (let key in start_fourth_page_animation) {
        start_fourth_page_animation[key] = false;
      }

      if (!show_all) {
        jq(".customer_line").remove();
      }
      start_five_page_animation = true;
      for (let key in bird) {
        bird[key] = false;
      }
      /* for (let key in customer_info) {
                 customer_info[key] = false
             }*/
      setTimeout(() => {
        bird.first_bird_active = true;
      }, 600);
      setTimeout(() => {
        bird.first_bird_active = false;
        bird.second_bird_active = true;
      }, 800);
      setTimeout(() => {
        bird.second_bird_active = false;
        bird.third_bird_active = true;
      }, 1000);
      setTimeout(() => {
        bird.third_bird_active = false;
        bird.fourth_bird_active = true;
      }, 1200);
      setTimeout(() => {
        bird.fourth_bird_active = false;
        bird.fifth_bird_active = true;
      }, 1400);
    } else {
      show_logo = true;
      customer_info = {
        first_customer_section: true,
        second_customer_section: true,
        third_customer_section: true,
        fourth_customer_section: true,
        first_customer_section_mobile: true,
        second_customer_section_mobile: true,
        third_customer_section_mobile: true,
        fourth_customer_section_mobile: true,
      };
    }
  }
  function clearFifthSection() {
    if (it_phone) {
      stop_fifth_animation = true;
    }
    clearInterval(animation_interval);
    show_description = false;
    show_all = false;
    c_timer = true;
    start_five_page_animation = false;

    for (let key in bird) {
      bird[key] = false;
    }
    for (let key in customer_info) {
      customer_info[key] = false;
    }
    for (let key in birds_puck) {
      birds_puck[key] = false;
    }

    jq(".customer_line").remove();
  }

  onMount(() => {
    //getScreen()
    getPartOfDay();
    isItPhone();
    //if (!it_phone) {
    fullpageInstance = new window.fullpage("#fullpage", {
      autoScrolling: true,
      scrollHorizontally: false,
      licenseKey: "40FE7A19-5E5C46B4-89D25608-41686ECC",
      anchors: [
        "home",
        "about",
        "our-portfolio",
        "values",
        "customers",
        "contact-us",
      ],
      navigation: true,
      navigationPosition: "left",
      slidesNavPosition: "top",
      scrollingSpeed: 1000,
      scrollOverflow: it_phone,
      menu: ".myNav",
      showActiveTooltip: true,
      normalScrollElements: "#normal_scroll_modal, .sixPage, .fixed-content",
      navigationTooltips: it_phone
        ? ""
        : [
            "HOME",
            "ABOUT",
            "OUR PORTFOLIO",
            "Values",
            "Customers",
            "Contact us",
          ],
      //responsiveWidth: 769,
      afterLoad: function (origin, destination, direction) {
        // !night_theme ? tooltip.style.color = '#111a38' : tooltip.style.color = '#fff';
        tooltip.style.color = "#111a38";
        tooltip.setAttribute("style", "opacity: 1; transition: all 0.1s");
        if (destination.anchor === "home") {
          tooltip_parent.removeAttribute("style");
          tooltip.removeAttribute("style");
          tooltip.setAttribute("style", "transform: translateY(0px)");
          show_logo = false;
          start_first_page_animation = true;
          tooltip.textContent = "HOME";
        } else if (destination.anchor === "about") {
          tooltip_parent.removeAttribute("style");
          tooltip.removeAttribute("style");
          tooltip.setAttribute("style", "transform: translateY(-26px)");
          show_logo = false;
          start_second_page_animation = true;
          tooltip.textContent = "ABOUT";
        } else if (destination.anchor === "our-portfolio") {
          tooltip.removeAttribute("style");
          tooltip.setAttribute("style", "transform: translateY(-32px)");
          tooltip.textContent = "OUR PORTFOLIO";
          if (!stop_third_animation) {
            animated = false;
            show_p_missions = true;
            let timeline = {
              200: () => {
                show_first_section_mission = true;
              },
              7500: () => {
                show_second_section_mission = true;
              },
              12500: () => {
                show_third_section_mission = true;
              },
            };
            //if(!it_phone){

            let max_time = 14000,
              step = 0;

            const runStep = () => {
              step = step + 100;
              if (timeline[step]) timeline[step]();
              if (step > max_time) clearInterval(animation_interval);
            };

            animation_interval = setInterval(runStep, 100);
            //}
            show_logo = true;
            start_third_page_animation = true;

            for (let key in start_fourth_page_animation) {
              start_fourth_page_animation[key] = false;
            }
          } else {
            show_p_missions = true;
            start_third_page_animation = true;
            // show_first_section_mission = true
            // show_second_section_mission = true
            // show_third_section_mission = true
          }
        } else if (destination.anchor === "values") {
          tooltip.textContent = "Values";
          tooltip_parent.setAttribute("style", "margin-top: -92px !important;");
          tooltip.removeAttribute("style");
          tooltip.setAttribute("style", "transform: translateY(-90px)");
          if (!stop_fourth_animation) {
            let timeline = {};
            show_logo = true;
            general_fourth_page_animation = true;
            for (let key in start_fourth_page_animation) {
              if (general_fourth_page_animation) {
                timeline[time_step] = () => {
                  start_fourth_page_animation[key] = true;
                };
                time_step = time_step + 1000;
              }
            }
            let max_time = 12000,
              step = 0;

            const runStep = () => {
              step = step + 100;
              if (timeline[step]) timeline[step]();
              if (step > max_time) clearInterval(animation_interval);
            };
            animation_interval = setInterval(runStep, 100);
          } else {
            show_logo = true;
            general_fourth_page_animation = true;
            start_fourth_page_animation = {
              animation_1: true,
              animation_2: true,
              animation_3: true,
              animation_4: true,
              animation_5: true,
              animation_6: true,
            };
          }
        } else if (destination.anchor === "customers") {
          tooltip.textContent = "Customers";
          tooltip.removeAttribute("style");
          tooltip.setAttribute("style", "transform: translateY(-107px)");
          if (it_phone) {
            console.log('destination customers')
            setTimeout(() => {
              console.log('rebuild 1000 ')
              fullpageInstance.reBuild()
            }, 1000)
          }
          const resizeHandler = (event) => {
            const clearTimer = () => {
              clearTimeout(resizeHandlerTimout);
              resizeHandlerTimout = null;
            };
            if (resizeHandlerTimout) {
              clearTimer();
            }
            console.log(event);
            resizeHandlerTimout = setTimeout(() => {
              clearFifthSection();
              fullpageInstance.reBuild();
              setTimeout(() => {
                initFifthPage();
              }, 100);
            }, 1000);
          };
          window.addEventListener("resize", resizeHandler);
          initFifthPage();
        } /*else if (destination.anchor === 'fivePage') {
                    window.addEventListener("resize", fivePageAnimation);
                    show_logo = true
                    setTimeout(() => {
                        bird.fifth_bird_active = false
                        customer_info.first_customer_section = true
                    }, 200)
                    setTimeout(() => {
                        customer_info.second_customer_section = true
                    }, 2000)
                    setTimeout(() => {
                        customer_info.third_customer_section = true
                    }, 4000)
                    setTimeout(() => {
                        customer_info.fourth_customer_section = true
                    }, 6000)
                    fivePageAnimation()
                } */ else if (
          destination.anchor === "contact-us"
        ) {
          if (it_phone) {
            console.log('destination customers')
            setTimeout(() => {
              console.log('rebuild 1000 ')
              fullpageInstance.reBuild()
            }, 1000)
          }
          tooltip.textContent = "Contact us";
          tooltip.removeAttribute("style");
          tooltip.setAttribute("style", "transform: translateY(-140px)");
          //sixPageRecolor()
          start_video = true;
          padding_for_nav = false;
          show_logo = true;
          jq(".customer_line").remove();
          start_six_page_animation = true;
          for (let key in bird) {
            bird[key] = false;
          }
          if (!it_phone) {
            for (let key in customer_info) {
              customer_info[key] = false;
            }
          }
        }
      },
      onLeave: function (origin, destination, direction) {
        tooltip.setAttribute("style", "opacity: 0; transition: all 0.1s");
        if (origin.anchor === "home") {
          start_five_page_animation = true;
          !it_phone ? (start_first_page_animation = false) : null;
          test_animate = false;
          setTimeout(() => {
            test_animate = true;
          }, 600);
        } else if (origin.anchor === "about") {
          start_second_page_animation = false;
        } else if (origin.anchor === "our-portfolio") {
          if (it_phone) {
            stop_third_animation = true;
          }
          start_third_page_animation = false;
          show_first_section_mission = false;
          show_second_section_mission = false;
          show_third_section_mission = false;
          clearInterval(animation_interval);
          animated = false;
          show_logo = false;
          start_third_animation = true;
          for (let key in show_third_page_descriptions) {
            show_third_page_descriptions[key] = true;
          }
          show_p_missions = false;
        } else if (origin.anchor === "values") {
          if (it_phone) {
            stop_fourth_animation = true;
          }
          clearInterval(animation_interval);
          general_fourth_page_animation = false;
          for (let key in start_fourth_page_animation) {
            start_fourth_page_animation[key] = false;
          }
          time_step = 1000;
        } else if (origin.anchor === "fivePage") {
          for (let key in bird) {
            bird[key] = false;
          }

          for (let key in customer_info) {
            customer_info[key] = false;
          }

          jq(".customer_line").remove();
        } else if (origin.anchor === "customers") {
          clearFifthSection();
        } else if (origin.anchor === "contact-us") {
          padding_for_nav = true;
          start_five_page_animation = false;
          start_video = false;
        }
      },
    });
  });
</script>

<Navbar
  {show_logo}
  blue_logo={padding_for_nav}
  {monitor_width}
  {monitor_height}
  is_phone={it_phone}
  {night_theme}
/>
<div class="container-fluid" style="overflow-x: hidden !important;">
  <div class="row" style="position: relative;">
    <div class="col-12 p-0">
      <div id="fullpage">
        <div class="section section_1">
          <SectionOne
            {it_phone}
            animate={start_first_page_animation}
            {night_theme}
          />
        </div>
        <div class="section section_2">
          <SectionTwo animate={start_second_page_animation} {night_theme} />
        </div>
        <div class="section section_1">
          <SectionThree
            animate={start_third_page_animation}
            {show_first_section_mission}
            {show_second_section_mission}
            {show_third_section_mission}
            {show_p_missions}
            bind:show_third_page_descriptions
            bind:start_third_animation
            is_phone={it_phone}
            bind:animated
            {night_theme}
          />
        </div>
        <div class="section">
          <SectionFour
            {...start_fourth_page_animation}
            animate={general_fourth_page_animation}
            on:show_all={callbackFunction}
            is_phone={it_phone}
            {night_theme}
          />
        </div>
        <div
          class="section"
          style={!night_theme
            ? "background-color: inherit;"
            : "background-color: #040019;"}
        >
          <SectionFive_1
            animate={start_five_page_animation}
            {...bird}
            is_phone={it_phone}
            {night_theme}
            {...customer_info}
            {...birds_puck}
            stop_animation={stop_third_animation}
            on:show_all_customer_position={callbackFunctionCustomer}
            {c_timer}
            bind:show_all
            bind:show_description
            fullpage={fullpageInstance}
          />
        </div>
        <!--<div class="section">
                    <SectionFive {...bird} {...customer_info}
                                 on:show_all_customer_position="{callbackFunctionCustomer}"
                                 is_phone={it_phone}
                                 {night_theme}/>
                </div>-->
        <div class="section" style="background-color: #d9d9d9;">
          <SectionSix {start_video} is_phone={it_phone} {night_theme} />
        </div>
      </div>
    </div>
    {#if !it_phone}
      <div class="modal_parent disable_modal">
        <Modal
          on:close={() => {
            dis_modal();
          }}
        />
      </div>
    {/if}
  </div>
</div>

<style>
  .padding_top {
    padding-top: 59px;
  }

  .modal_parent {
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.2s ease-in;
  }

  p {
    color: white;
  }

  .page_done {
    height: 0;
    transition-property: all;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-delay: 0s;
  }

  #page_preloader {
    width: 100vw;
    height: 100vh;
    z-index: 100;
    position: fixed;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    background-color: #c5a01e;
  }
</style>
