<script>
    import { createEventDispatcher, onDestroy } from 'svelte';

    const dispatch = createEventDispatcher();
    const close = () => dispatch('close');
</script>
<style>
    .send_button {
        background-color: inherit !important;
        width: 44px;
        height: 44px;
        border-radius: 100%;
        outline: none;
        border: 0;
        margin-bottom: 35px;
    }
    /*.send {

        width: 21px;
        height: 20px;

    }*/
</style>
<button class="send_button" on:click="{()=>{close()}}">
    <img src="imgs/backarrow.svg" alt="" class="send">
</button>