export function DrawZipLine(id) {
    const blocks = [['bird_1', 'bird_2'], ['bird_2', 'bird_3'], ['bird_3', 'bird_4']] //['bird_2', 'bird_3'], ['bird_3', 'bird_4']
    DrawLine(blocks[id], id)
}

const DrawLine = (currentValue, index, array) => {
    let first_el,
        second_el,
        first_point,
        second_point;
    /**
     *Find two elements between which there will be a line
     */
    first_el = window.jq('#' + currentValue[0]);
    second_el = window.jq('#' + currentValue[1]);
    /**
     * Find first point position
     */
    first_point = first_el.offset();
    first_point.left = first_point.left + first_el.width();
    first_point.top = first_point.top - (first_el.height() / 2) - 21;
    /**
     * Find second point position
     */
    second_point = second_el.offset();
    second_point.top = second_point.top - (second_el.height() / 2);
    /**
     * Calculation height and width for svg element
     * @type {number}
     */
    let width = second_point.left - first_point.left, //width of svg
        height = 2,
        svgTop = first_point.top + 50,
        svgLeft = first_point.left,
        x1 = 0,
        x2 = width;
    /*if(index === 0){
        svgTop = svgTop + 4
    } else if (index === 2){
        svgTop = svgTop + 7
    }
    if (width < 0) {
        width = Math.abs(width);
        svgLeft = second_point.left;
        x1 = width;
        x2 = 0  #D1D2D8
    }*/

    const svg = `<svg width="${width}" height="${height}" style=" position: absolute; top: ${svgTop}px; left: ${svgLeft}px; z-index: 0;"  class="customer_line">
<line class="str" stroke="#D1D2D8" stroke-width="2" stroke-dasharray="2" x1="${x1}" y1="1" x2="${x2}" y2="1" /></svg>`

    window.jq("body").append(svg)
}