<script>
    import {fade} from 'svelte/transition';

    export let animate
    export let night_theme
    export let it_phone
</script>
<style>
    .sub_title {
        font-size: 39px
    }

    .flex {
        display: flex;
    }

    .content_svg {
        margin: 24% auto auto;
    }

    @media (max-width: 1280px) {
        .content_svg {
            margin-top: 16%;
        }
    }

    @media (max-width: 1024px) {
        .sub_title {
            font-size: 24px;
            line-height: 30px;
        }
    }

    @media (width: 768px) {
        .sub_title {
            font-size: 34px;
            margin-bottom: 15px !important;
        }

        .font_26_16 {
            font-size: 18px;
        }
    }

    @media (width: 1024px) {
        .sub_title {
            font-size: 39px;
            line-height: 55px;
        }
    }

    @media screen and (max-width: 768px) {
        .flex img {
            width: 70% !important;
        }
    }

    @media screen and (max-width: 400px) {
        .font_44_bold_34 {
            font-size: 20px
        }
        .padding-left {
            padding-left: 40px;
        }
    }
</style>
<div class="row 1">
    <div class="col-12 flexed_box">
        <!--      {#if it_phone}-->
        <!--        <div class="custom-tooltip">Tooltip</div>-->
        <!--      {/if}-->
        <!--HOME-->
        {#if animate}
            <div class="section_content auto_margin  " transition:fade="{{ duration: 100 }}">
                <div class="wow fadeIn" style="margin-top: -100px" data-wow-duration="1s">
                    <div class="flex padding-left"><img src={night_theme ? 'imgs/night/main_title_night.svg' : 'imgs/title.svg'}
                                            alt="" class="content_svg" style="height: 90%; width: 90%"></div>
                    <p class="font_44_bold_34 center_text sub_title padding-left" style="padding-top: 5%; margin-bottom: 0; ">The
                        Intelligent Collective</p>
                    <p class="font_26_16 center_text padding-left" style="font-weight: 400; color: #101b38">Benefit from the
                        intelligence of our decentralized,<br>
                        self-organized
                        system.</p>
                </div>
            </div>
        {/if}
        <!--HOME-->
    </div>
</div>
